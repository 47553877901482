import React from 'react'
import hsaimg from '../images2/HSA.jpg'
import offeringsbg from '../images2/offering-bg.png'
const hsa = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid h-80 product-inside flex flex-col items-center grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${hsaimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className=''>
      <h2 className='text-3xl font-bold uppercase'>Empower Your Clinic with Our  <span className='text-red-500'> HSA Clinic</span></h2>
      <p className='xxs:text-sm xs:text-base'>Experience the next generation of clinic management with our cutting-edge solution designed to 
      seamlessly integrate Electronic Medical Records (EMR), Electronic Health Records (EHR), and medical 
      billing processes. Our Innovative HSA Clinic is tailored to meet the unique demands of modern healthcare
       practices, offering a comprehensive platform to optimize patient care and administrative efficiency.</p>
       </div>

       <div>
      
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='font-bold mt-12 text-2xl'>KEY<span className='text-red-500'> FEATURES</span></h2>
        </div>
       </div>
       <div className='grid xxs-grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integrated EMR/EHR 
       </h2>
       <p>
       Streamline patient data management and medical records with a unified EMR/EHR system, 
       ensuring secure access to comprehensive patient information and encounter history. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Billing and Claims Management
       </h2>
       <p>
       Simplify medical billing processes, automate claim submissions, and manage
        reimbursements efficiently, reducing administrative burden and enhancing financial performance.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Appointment Scheduling
       </h2>
       <p>
       Facilitate seamless appointment scheduling, 
       patient reminders, and calendar management, optimizing clinic workflow and patient experience. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customizable Templates
       </h2>
       <p>
       Customize encounter notes, forms, and templates
        to align with specialty-specific requirements, ensuring accurate documentation and compliance. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Reporting and Analytics
       </h2>
       <p>
       Access advanced reporting tools and analytics for insights into
        clinic performance, patient outcomes, and financial metrics, empowering data-driven decision-making.
       </p>
       </div>
       
       
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 bg-white mx-6 my-6'>
       <div className='lg:border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Enhanced Patient Care
       </h2>
       <p>
       Improve clinical decision-making, streamline care coordination, 
       and ensure continuity of patient care through comprehensive EMR/EHR capabilities. </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Operational Efficiency
       </h2>
       <p>
       Optimize administrative workflows, reduce paperwork, 
       and minimize manual errors, allowing staff to focus on patient care and practice growth. </p>
       </div>
       <div className='lg:border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Revenue Optimization 
       </h2>
       <p>
       Maximize revenue cycle management, minimize claim denials, and accelerate
        reimbursement cycles, bolstering financial sustainability and profitability.   
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance 
       </h2>
       <p>
       Stay abreast of healthcare 
       regulations and compliance standards, mitigating risks and ensuring adherence to industry requirements.
       </p>
       </div>
       
       </div>
       <h2 className='m-6 text-red-500 font-bold'>
       Experience the Transformation:
       </h2>
       <p className='mx-6'>Join the ranks of forward-thinking healthcare providers who have embraced our Innovative HSA Clinic to elevate patient care, streamline operations, and achieve sustainable growth. Embrace innovation, precision, 
       and control with a system that caters to the unique demands of your clinic.

       </p><p className='mx-6 pb-6'>Unlock the potential of seamless EMR/EHR management and efficient medical billing with our comprehensive Clinic Management System.</p>
       </div>

       
       </div>
  )
}

export default hsa
