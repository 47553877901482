import React from 'react'
import offeringsimg from '../images2/products.png'

const comingsoon = () => {
  return (
    <div>
    
    <div style={{color:'#373A4A'}} className='comingsoon h-screen flex justify-center items-center'>
      <h2 className='text-2xl sm:text-6xl font-bold uppercase border-t-2 border-b-2 border-t-red-500 py-6'>Coming <span className='text-red-500'> soon</span></h2>
      
       </div>
       <div>
      
       </div>
      
       <div>
       
       </div>
       
       
       </div>
  )
}

export default comingsoon
