import React from 'react'

const Tatmeen = () => {
  return (
    <div> 
   

<iframe src="./doc/Tatmeen2.pdf" className=' md:w-full md:h-screen' frameborder="0"></iframe>

    </div>
  )
}

export default Tatmeen
