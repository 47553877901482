import React from 'react'
import warehouseimg from '../images2/group.jpg'

const warehouse = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid grid-cols-1 lg:grid-cols-2 my-6 mx-6'>
    <div className='flex flex-col justify-center'>
      <h2 className='text-xl lg:text-3xl font-bold uppercase'>Welcome to Our  <span className='text-red-500'>Integrated Distributor Network Solution </span></h2>
      <p className=''>We understand the complexities of managing a network of pharmacies, distributors, and warehouses in the healthcare industry. That's why we've developed a comprehensive suite of solutions tailored to meet the unique needs of distributors with multiple interconnected branches and a network of distribution.</p>
       </div>
       <div className=''>
       <img className='' src={warehouseimg}/>
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='font-bold  text-xl lg:text-3xl uppercase'>Products and <span className='text-red-500'>Features</span> </h2>
     
       </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2  gap-x-3 my-6 mx-6'>
       
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
 eInventory 
       </h2>
       <p className='text-sm md:text-base'>
       Efficient inventory management is crucial for wholesale/distributor business to ensure smooth operations and minimize stockouts. Our eInventory system provides real-time visibility into your inventory, enabling you to optimize stock levels, track expiration dates, and automate reordering processes.
       </p>
       </div>
       
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Sales and Inventory Optimization Dashboards and Analytics
       </h2>
       <p className='text-sm md:text-base'>
       Make informed decisions and drive profitability with our sales and inventory optimization dashboards. Gain valuable insights into sales trends, inventory turnover rates, and product performance across your entire network, empowering you to make data-driven decisions and maximize revenue.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Centralized Reporting Module
       </h2>
       <p className='text-sm md:text-base'>
       Simplify reporting processes and gain a comprehensive view of your business with our centralized reporting module. Generate customized reports on sales, inventory, financial performance, and more, consolidating data from your distribution network for easy analysis and decision-making.
       </p>
       </div>
       
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Accounts Management System
       </h2>
       <p className='text-sm md:text-base'>
       Managing accounts across huge distribution network can be challenging, but our Accounts Management System simplifies the process. With centralized accounts management, you can track transactions, reconcile accounts, and generate financial reports with ease, ensuring accurate financial management for your entire network.
       
       </p>
       </div>
       
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration with RSD (KSA) and Tatmeen (UAE)
       </h2>
       <p className='text-sm md:text-base'>
       Our solutions are seamlessly integrated with RSD (KSA) and Tatmeen (UAE), allowing you to comply with local regulations and standards while maximizing operational efficiency and profitability.
       </p>
       </div>
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>WHY CHOOSE US</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-2 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Comprehensive 
Solution
       </h2>
       <p>
       Our suite of integrated solutions covers all aspects of distributor management, from point of sale to inventory management to accounts and patient management.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Scalability
       </h2>
       <p>
       Whether you have a small distribution network or a large, we have got you covered with our scalable solutions.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Seamless Integration       
       </h2>
       <p>
       Our systems are designed to integrate seamlessly with existing workflows and third-party platforms, minimizing disruptions and maximizing efficiency.</p>
       </div>
       
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Dedicated Support
       </h2>
       <p>
       We provide dedicated customer support to ensure that your experience with our solutions is smooth and hassle-free. Our team is available to assist you with implementation, training, and ongoing support.</p>
       </div>
       
       </div>
       <div className='p-6'>
       <h2 className='font-bold text-red-500'>Take Your Distribution Network To The Next Level
       </h2>
       <p className=''>Experience the difference that our integrated distributor solutions can make for your business. Streamline operations, improve efficiency, and drive profitability across your entire network. Get in touch with us today to learn more and schedule a demo!</p>
       </div>
       </div>
       </div>
  )
}

export default warehouse
