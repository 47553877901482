import React from 'react'
import einventoryimg from '../images2/inventory.jpg'
import offeringsbg from '../images2/offering-bg.png'
const einventory = () => {
  return (
    <div className='text-slate-700'>
    <div className='product-inside grid h-80 grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${einventoryimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='text-slate-700 flex flex-col justify-center'>
      <h2 className='xxs:text-lg sm:text-2xl font-bold uppercase'>Revolutionize Your <span className='text-red-500'>Inventory Management</span> with Our Cutting-Edge System</h2>
      <p className='xxs:text-sm sm:text-base'>Welcome to the future of seamless inventory control and optimization. Our state-of-the-art eInventory 
      Product is designed to meet the diverse and dynamic needs of 
      modern industries, offering a comprehensive solution that streamlines operations and maximizes efficiency.</p>
       </div>

       <div>
      
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='text-red-500 text-2xl mt-6 text-slate-700 font-bold'>KEY <span className='text-red-500'>FEATURES</span></h2>
        </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Real-time Tracking
       </h2>
       <p>
       Gain full visibility into your inventory with real-time tracking,
        ensuring accurate and up-to-date information on stock levels, locations, and movements. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customizable Dashboards
       </h2>
       <p>
       Tailor the system to your specific industry requirements 
       with customizable dashboards, providing intuitive insights and analytics for informed decision-making.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Automated Alerts
       </h2>
       <p>
       Stay ahead of inventory fluctuations and potential shortages 
       with automated alerts and notifications, allowing proactive management and replenishment.   </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration Capabilities
       </h2>
       <p>
       Seamlessly integrate with existing ERP systems, e-commerce 
       platforms, and supply chain networks for synchronized data flow and enhanced operational synergy.  </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Scalability and Flexibility
       </h2>
       <p>
       Adapt to evolving business needs with a scalable and 
       flexible system that accommodates growth, diversification, and changing industry dynamics.    </p>
       </div>
       
       
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS OF EINVENTORY</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Enhanced Efficiency
       </h2>
       <p>
       Optimize inventory workflows, minimize manual errors, 
       and increase operational efficiency, leading to cost savings and improved productivity.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Accurate Forecasting
       </h2>
       <p>
       Utilize advanced analytics and forecasting tools to anticipate demand, 
       minimize excess stock, and optimize inventory turnover. </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance
       </h2>
       <p>
       Ensure adherence to industry regulations and standards, maintaining compliance and
        mitigating risks associated with inventory management.
       
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       	Customer Satisfaction 
       </h2>
       <p>
       Fulfill orders promptly, prevent stockouts, and deliver superior customer experiences through reliable inventory management practices.</p>
       </div>
       </div>
       <h2 className='mt-6 mx-6 text-red-500 font-bold'>
       Experience the Difference:
       </h2>
       <p className='mx-6 pb-6'>Join the ranks of industry leaders who have embraced our eInventory Product to elevate their operations
        and stay ahead in today's competitive landscape. Embrace innovation, precision, 
       and control with a system that caters to the unique demands of your industry.

       Take the next step towards efficiency, accuracy, and success with our cutting-edge eInventory Product.</p>
       </div>
       </div>
  )
}

export default einventory
