import React from 'react';
import '../input.css';

import "../App.css"
import {AiOutlineClose} from "react-icons/ai"
import {AiFillHome} from "react-icons/ai"
import {CgProfile} from "react-icons/cg"
import {AiOutlineProfile} from "react-icons/ai"
import {BsBriefcase} from "react-icons/bs"
import {BiMessageAltDetail} from "react-icons/bi"
import {SiMinds} from 'react-icons/si'
import { Link } from 'react-router-dom';
import logo from '../images2/logo-3.png'
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { FaBars } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useClickAway } from "@uidotdev/usehooks";
import { useEffect } from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
  } from '@chakra-ui/react'
  import { BiChevronDown } from 'react-icons/bi'


const Navbar = () => {
  const [show, setshow] = useState(true);
  const [dropdownshow, setdropdownshow] = useState(false)
 const checkkar = ()=>{
  setshow(!show)
   console.log(show)
 }
 const dropdownopen = ()=>{
    setdropdownshow(true)
 }

 const [isOpen, setIsOpen] = React.useState(false);
  const ref = useClickAway(() => {
    setdropdownshow(false);
  });
  const closethings = () =>{
    setdropdownshow(false);
    setshow(true)
  }
 







  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.matches('a[href^="#"]')) {
        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          setTimeout(() => {
            targetElement.classList.add('offset');
          }, 10);
        }
      }
    };

    const handleScroll = () => {
      const offsetElements = document.querySelectorAll('.offset');
      offsetElements.forEach(el => {
        el.classList.remove('offset');
      });
    };

    document.addEventListener('click', handleClick);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('click', handleClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);












    return (
      <div className='relative flex items-center' style={{height:"80px"}}>

      
      <div className='fixed shadow-2xl flex bg-white items-center w-full' style={{zIndex:"9999"}} id="mainheader">

      <button className='fixed bar top-0 py-4 px-4 text-2xl right-0 mt-6 text-slate-700' onClick={checkkar}>{show ? <FaBars /> : <ImCross />}</button>
   
      <HashLink to="/#hero6" class="navlink active xxs:w-3/4 sm:w-1/2 lg:w-1/3">
      <img src={logo} className='ps-4 navbar-logo h-20'/> </HashLink> 
      <div className={`flex justify-end text-white items-center z-0 py-12 ${show ? "left-full" : "right-0"}`} id='header'>
    
       <div className="menu navbar font-bold me-12 text-black flex items-center justify-between" style={{color:"#373A4A"}}>
            <HashLink to="/#hero6" class="navlink active px-6" onClick={()=>{setshow(true)}}>
              Home
              
            </HashLink>
            <div class="navlink px-6 relative">
              
             
  <button onClick={dropdownopen} onMouseOver={dropdownopen} onMouseOut={()=>setdropdownshow(false)} className='products-button'>
    Products
  </button>
  <div ref={ref}  onMouseOver={dropdownopen} onMouseOut={()=>setdropdownshow(false)} className={`w-60 dropdown-heaven absolute shadow-2xl bg-white px-8 py-4 border rounded ${dropdownshow ? "block" : "hidden"}`}
  >
  <ul className='space-y-4'>
  <li className='dropdown-list'>
  <Link to="/products/ezpos" class="navlink active products-button"  onClick={closethings}>
  EzPOS
  
</Link>
</li>
<li className='dropdown-list'>
<Link to="/products/ezrx" class="navlink active products-button"  onClick={closethings}>
EzRX

</Link>
</li>
<li className='dropdown-list'>
    <Link to="/products/hsa" class="navlink active products-button"  onClick={closethings}>
    HSA Clinic
    
  </Link>
  </li>
  <li className='dropdown-list'>
    <Link to="/products/dashboard" class="navlink active products-button" onClick={closethings}>
    Dashboard Analytics
    
  </Link>
  </li>
  <li className='dropdown-list'>
    <Link to="/products/einventory" class="navlink active products-button" onClick={closethings}>
    eInventory
    
  </Link>
  </li>
  <li className='dropdown-list'>
    <Link to="/products/ecommerce" class="navlink active products-button" onClick={closethings}>
    HSA E-Commerce
    
  </Link>
  </li>
   <li className='dropdown-list'>
<Link to="/products/ams" class="navlink active products-button" onClick={closethings}>
AMS

</Link>
</li>
<li className='dropdown-list'>
<Link to="/products/campusteck" class="navlink active products-button" onClick={closethings}>
CampusTeck
</Link>
</li>
<li className='dropdown-list'>
<Link to="/products/fintech" class="navlink active products-button" onClick={closethings}>
FinTech

</Link>
</li>
</ul>
</div>


           </div>
            <HashLink to="/#offering" class="navlink px-6" onClick={()=>{setshow(true)}}>
                Offerings
            </HashLink>
        
            <Link to="/comingsoon" class="navlink px-6" onClick={()=>{setshow(true)}}>
                E-commerce
            </Link>
            <Link to="/aboutus" class="navlink px-6 " onClick={()=>{setshow(true)}}>
            About Us
            
            </Link> 
            
            <div> 
        
        </div>
        </div>
        <HashLink to="/#contactus" className='px-8 py-3 text-white border header-button rounded-full mx-12 bg-slate-700'>Contact Us</HashLink>
        </div>
        </div>
        </div>
  )
}

export default Navbar