import React from 'react'
import gln from '../images2/gln.png'
import login from '../images2/login.png'
import Tatmeenhomepage from '../images2/tatmeenhomepage.png'
import spocadmin from '../images2/spocadmin.png'
import inviteuser from '../images2/inviteuser.png'
import createuser from '../images2/createuser.png'
import adduser from '../images2/adduser.png'

import register from '../images2/register.png'
import registerform from '../images2/registerform.png'
import tatmeenstaging from '../images2/tatmeenstaging.png'
import developportal from '../images2/developportal.png'
import tatmeensignin2 from '../images2/tatmeensignin2.png'
import tatmeenhomepage2 from '../images2/tatmeenhomepage2.png'
import spocadmin2 from '../images2/spocadmin2.png'
import inviteuser2 from '../images2/inviteuser2.png'
import adduser2 from '../images2/adduser2.png'
import createuser2 from '../images2/createuser2.png'
import register2 from '../images2/register2.png'
import registerform2 from '../images2/registerform2.png'
import tatmeenapiportal from '../images2/tatmeenapiportal.png'
import developerportal2 from '../images2/developerportal2.png'
import { Link } from 'react-router-dom'
import signin from '../images2/signin.png'

const tatmeenonboarding = () => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = './doc/Tatmeen2.pdf'; // Path to your PDF file
    link.download = 'Tatmeen2.pdf'; // The file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };
  return (
    <div className='my-12 px-6 m-auto space-y-2 w-full md:w-3/4 text-slate-700'>
      <h2 className='my-6 text-3xl font-bold  text-center uppercase'>
      Onboarding Tatmeen Made Easy
      </h2>
      <h3 className='text-2xl'>1. Introduction</h3>
      <p className=''>Navigating the complexities of integrating with Tatmeen can be a daunting task for pharmacy 
      retailers and distributors. However, with the expertise and support of Healthcare Software Alliance 
      LLC (HSA), the process becomes streamlined and hassle-free. In this article, we'll explore 
      how to get onboard with Tatmeen and leveraging their software to enhance efficiency and productivity. </p>
      <h3 className='text-2xl'>2. Tatmeen Onboarding Process </h3>
      <h2 className='text-xl'>2.1 First Register with GS1 and get your Global Location Number (GLN)</h2>
    <p className=''>The first step in registering with Tatmeen is obtaining a GLN from GS1. </p>
   <p> Create user GLN (Global Location Number) from GS1 website Get a Global Location Number (GLN) | GS1 US
  <Link className='text-blue-500' to='https://www.gs1us.org/industries-and-insights/standards/global-location-number/get-a-global-location-number'>   (https://www.gs1us.org/industries-and-insights/standards/global-location-number/get-a-global-location-number)</Link>
      
     </p>
    <img src={gln} className='my-6 h-auto sm: h-auto sm:h-80'/>
     <p>Once obtained Tatmeen platform will receive the GLN details from GS1 and release 
     the registration link to the single point of contact of that GLN who can proceed with 
     the registration.</p>
     <h2 className='text-2xl'>
     3. Tatmeen Staging Platform Registration  
     </h2>
     <p>
     Once obtained Tatmeen platform will receive the GLN details from GS1 and GS1 will send you password creation
      link for the single point of contact (SPOC) of that GLN who can proceed with the registration.
     </p>
     <p className='text-red-500 font-bold'>
     Note: in case you miss the GS1 emailed link or forgot your password, 
     please email  onboarding@tatmeen.ae with your GLN and request to resent link to set your password. 
     </p>
     <h3 className='text-xl'>3.1 Login on Tatmeen </h3>
     <p> Visit <Link to="https://stgportal.tatmeen.ae/login" className='text-blue-500'> https://stgportal.tatmeen.ae/login</Link></p>
     <p>
     Access your Single Point of Contact (SPOC) account by logging in with the credentials provided through the registration link from Tatmeen. Simply use the 
     email address you used during your GLN registration and the password you set up via 
     the provided link.</p>
     <img src={login} className='my-6  h-auto sm:h-80 '/>
     <h3 className='text-xl'>3.2 SPOC User Home page </h3>
     <img src={Tatmeenhomepage} className='my-6  h-auto sm:h-80' />
     <h3 className='text-xl'>3.3 Create a Service User </h3>
     <h3 className='font-bold'>Step 1: Navigate to SPOC Admin </h3>
     <p>Click on the user icon, from the dropdown select “SPOC Admin”. </p>
     <img src={spocadmin} className='my-6  h-auto sm:h-80' />
     <p>“Tatmeen – Organization Administrator/SPOC” screen opens.</p>
     <h3 className='mt-6 font-bold'>Step 2: Invite User </h3>
     <p>To generate a 'Service User,' simply click on the 'Invite User' button. It's important to note that the Service User should
      be distinct from the SPOC user.
       Please ensure you use a separate email address for this purpose</p>
       <img src={inviteuser} className='my-6  h-auto sm:h-80' />
       <p>From the popup window select the user type to “Service” </p>
       <img  src={adduser} className='my-6  h-auto sm:h-80'/>
       <p>Enter User details such as First Name, Last Name, 
       Support Email (Different from SPOC user Email) and his location and click “Create User” </p>
       <img src={createuser} className='my-6  h-auto sm:h-80' />
       <p>A confirmation link will be sent to the email mentioned open link 
       and create password for the account.
       Once the Service user created visit 
    <Link to ='https://stgapihub.tatmeen.ae/' className='text-blue-500'>  (https://stgapihub.tatmeen.ae/) </Link> 
       Tatmeen Staging portal and login using just created Service User credentials.
       </p>
       <img src={login} className='my-6  h-auto sm:h-80' />
       <p>Upon successful login, the Tatmeen staging portal will prompt you
        to register for the 'API Business Hub Enterprise' to create staging API URLs for testing.</p>
       <img src={register}  className='my-6  h-auto sm:h-80'/>
       <p>When you click on the Register button, a popup will appear, allowing you to create your Staging APIs.
        It will prompt you to provide the reason for usage and the location where it will be utilized.</p>
        <img src={registerform} className='my-6  h-auto sm:h-80' />
        <p>Click “OK” button to register for Staging APIs. The following screen appears. </p>
        <img src={tatmeenstaging} className='my-6  h-auto sm:h-80' />
        <p>Great! Your registration for Tatmeen Staging APIs is completed. Tatmeen will take 24 to 48 hours and inform you via confirmation email. <br/>
        After receiving confirmation email from Tatmeen. Login with Service user and you will navigate to following this Home Page 
        </p>
        <img src={developportal} className='my-6  h-auto sm:h-80' />
        <h2 className='text-2xl'>4. Tatmeen Production Portal Registration. </h2>
        <h3 className='text-xl'>4.1 Login on Tatmeen </h3>
        Visit <Link to='https://tatmeen.mohap.gov.ae/login' className='text-blue-500'> https://tatmeen.mohap.gov.ae/login </Link>
        <p>Access your Single Point of Contact (SPOC) account by logging in with the credentials provided through the registration link from Tatmeen. Simply use the 
        email address you used during your GLN registration and the password you set up via the provided
         link.</p>
         <img src={signin} className='my-6  h-auto sm:h-80' />
         <p className='text-xl'>4.2 SPOC User Home page </p>
         <img src={tatmeenhomepage2} className='my-6  h-auto sm:h-80' />
         <h3 className='text-xl'>4.3 Create a Service User </h3>
         <h3 className='font-bold'>Step 1: Navigate to SPOC Admin </h3>
         <p>Click on the user icon, from the dropdown select “SPOC Admin”. </p>
         <img src={spocadmin2} className='my-6  h-auto sm:h-80' />
         <p>“Tatmeen – Organization Administrator/SPOC” screen opens.</p>
         <h3 className='mt-6 font-bold'>Step 2: Invite User </h3>
         <p>To generate a 'Service User,' simply click on the 'Invite User' button. It's important
          to note that the Service User should
          be distinct from the SPOC user. Please ensure you use a separate email address 
          for this purpose.</p>
          <img src={inviteuser2} className='my-6  h-auto sm:h-80' />
          <p>From the popup window select the user type to “Service” </p>
          <img src={adduser2} className='my-6  h-auto sm:h-80'/>
          <p>Enter User details such as First Name, 
          Last Name, Support Email (Different from SPOC user Email) and his location and click “Create User” </p>
          <img src={createuser2} className='my-6  h-auto sm:h-80' />
          <p>A confirmation link will be sent to the email mentioned open link and create password for the account.
          Once the Service user created visit <Link className='text-blue-500' to='https://tatmeenapihub.mohap.gov.ae'> (https://tatmeenapihub.mohap.gov.ae/)</Link> Tatmeen Production portal and login using just created Service User credentials.
          </p>
          <img src={tatmeensignin2} className='my-6  h-auto sm:h-80' />
          <p>Upon successful login, the Tatmeen Production portal
           will prompt you to register for the 'API Business Hub Enterprise' to create Production API URLs.</p>
           <img src={register2} className='my-6  h-auto sm:h-80' />
           <p>When you click on the Register button, a popup will appear, allowing you to create your Production APIs.
            It will prompt you to provide the reason for usage and the location where it will be utilized.</p>
            <img src={registerform2} className='my-6  h-auto sm:h-80' />
            <p>Click “OK” button to register for Production APIs. The following screen appears. </p>
            <img src={tatmeenapiportal} className='my-6  h-auto sm:h-80' />
            <p>
            Great! Your registration for Tatmeen Production
             APIs is completed. Tatmeen will take 24 to 48 hours and inform you via confirmation email. 
            </p>
            <p>
            After receiving confirmation
             email from Tatmeen. Login with Service user and you will navigate to following this Home Page 
            </p>
            <img src={developerportal2} className='my-6  h-auto sm:h-80'/>
            <div>
<div className='text-center my-12'>
<button onClick={handleDownload} className='download hover:text-rose-700 hover:bg-white border-rose-700 text-white rounded-full bg-rose-700 border px-6 py-3 fixed text-sm bottom-2 font-bold left-24 transform -translate-x-1/2 -translate-y-1/2'>Download</button></div>
</div>
     </div>

  )
}

export default tatmeenonboarding
