import React from 'react'
import amsimg from '../images2/AMS4.jpg'
import offeringsbg from '../images2/offering-bg.png'
const ams = () => {
  return (
    <div className='text-slate-700 '>
    <div className='grid h-80 product-inside grid-cols-1 xl:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${amsimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='flex flex-col justify-center'>
      <h2 className='xxs:text-lg xs:text-2xl uppercase font-bold'>Streamline Financial Operations with Our  <span className='text-red-500'> Accounts Management System</span></h2>
      <p className='xxs:text-xs xs:text-base'>Empower your organization with a robust Accounts Management System designed to cater to the diverse
       accounting requirements of key business systems including Inventory Management, FinTech, Higher Education ERP, eCommerce, Sales, and Procurements. Our comprehensive solution offers seamless integration, automation, and oversight of financial
       transactions, ensuring accuracy, compliance, and financial control across multiple operational domains.</p>
       </div>

       <div>
      
       </div>
       </div>
       <div>
       <div className='mx-6 mt-6 text-2xl'>
       <h2 className='text-slate-700 font-bold'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Multi-System Integration
       </h2>
       <p>
       Seamlessly integrate financial data from Inventory Management, FinTech, Higher Education 
       ERP, eCommerce, Sales, and Procurement platforms for centralized accounting management. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Unified Financial Reporting
       </h2>
       <p>
       Consolidate financial records, transactions, and reporting across various business systems,
        enabling comprehensive insights into revenue, expenses, and financial performance.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Automated Bookkeeping
       </h2>
       <p>
       Streamline transaction recording, reconciliation, and ledger maintenance, minimizing
        manual intervention and promoting error-free financial data management. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Compliance and Audit Readiness
       </h2>
       <p>
       Facilitate adherence to regulatory standards, financial 
       best practices, and audit requirements, ensuring transparency, accuracy, and accountability. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customizable Financial Workflows
       </h2>
       <p>
       Tailor financial processes, approvals, and workflows to align with the specific
        needs of diverse operational domains, promoting efficiency and control.
       </p>
       </div>
       
       
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS FOR STANDALONE PHARMACIES</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Financial Visibility
       </h2>
       <p>
       Gain a consolidated view of financial activities, cash flows, and monetary interactions across
        Inventory Management, FinTech, Higher Education ERP, eCommerce, Sales, and Procurements. </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Operational Efficiency
       </h2>
       <p>
       Enhance efficiency in financial operations, reduce redundancy, 
       and optimize resource allocation through streamlined accounting practices. </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Risk Mitigation
       </h2>
       <p>
       Identify financial risks, discrepancies, and anomalies
        early on, enabling proactive measures and mitigation strategies to safeguard financial integrity.   
       </p>
       </div>
       
       </div>
       <h2 className='mt-6 mx-6 text-red-500 font-bold'>
       Experience Financial Harmony Across Diverse Domains
       </h2>
       <p className='mx-6'>Elevate your financial management capabilities with an Accounts Management System that harmonizes accounting practices across multiple business systems. From inventory valuations to online transactions, academic finances, and procurement
        expenditures, our solution ensures financial integrity and transparency.

       </p><p className='mx-6 pb-6'>Simplify financial oversight and control with our comprehensive Accounts Management System.</p>
       </div>
       </div>
  )
}

export default ams
