import React from 'react';
import { BsTelephone } from "react-icons/bs";
import { GiRotaryPhone } from "react-icons/gi";
import { BsLink45Deg } from "react-icons/bs";
import contactimg2 from '../images2/map-2.png';
import Data from '../components/data'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';



const Footer6 = () => {
 
  return (
    <div id="footer6" >
    <div className='grid bg-zinc-50 grid-cols-1 lg:grid-cols-2 relative py-6 text-slate-700' id="contactus">
<div className='bg-white mx-8'>

<h2 className='uppercase mt-12 font-bold lg:text-3xl md:text-2xl xxs:text-base text-center'>
Contact <span className='text-red-500'> Us</span>
</h2>

{Data[14].desc.map((cont)=>(<p className='text-center xxs:text-xs md:text-sm lg:text-base'>{cont}</p>))}
 
<form className='m-auto w-3/4 text-slate-700 font-semibold sm:text-sm lg:text-base' action="https://api.web3forms.com/submit" method="POST">
<input type='hidden' name="access_key" value="3a026cd6-4006-47b4-b133-263c0c9dd782" />
      <label>Name: <pr></pr>
        <input type="text" name="name" required className='border w-full mb-4' style={{background:"#F3F3F3"}}/>
      </label>
      <pr></pr>
      <label>Email:<pr></pr>
        <input type="text" name="email" required className='border w-full mb-4' style={{background:"#F3F3F3"}}/>
      </label>
      <pr></pr>
      <label>Message:<pr></pr>
        <textarea type="text" name="message" required className='border h-72 w-full mb-4' style={{background:"#F3F3F3"}} />
      </label><pr></pr>
      
 
      <input className='border px-6 py-2 w-full bg-red-500 font-bold text-sm md:text-base cursor-pointer text-white' type="submit" value="Send Message"/>
    </form>
</div>
<div>
<img src={contactimg2} className='contactimg2' />
</div>
</div>
  


    <div className='relative text-xs bg-slate-700'>
    <div className='grid xxs:grids-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-6'>
    <div className='flex flex-col items-center'>
    <p className='text-white font-bold'>Healthcare Software Alliance (HSA), UAE
    </p><p className='text-white'> Office No 901, Nawat Building,{/** P.O. Box No. 31702,Mawasim Street */} Abu Dhabi, UAE.
    </p>
    
   <p className='text-white flex'><BsTelephone /> +971 (2) 666 3009</p>
   <p className='text-white flex'><GiRotaryPhone /> +971 56 4332443</p>
   <p className='text-white flex pb-3'><BsLink45Deg /> www.hsa-mena.com</p></div>
   <div className='flex flex-col items-center'>
   <p className='text-white font-bold'>Healthcare Software Alliance (HSA), KSA</p><p className='text-white'>
   Building Babtain, Suite 206, Olaya Street, Riyadh, KSA    </p>
   <p className='text-white flex '><BsTelephone /> +966 11 284 8882</p>
   <p className='text-white flex'><GiRotaryPhone /> +966 507 271397</p>
   <p className='text-white flex'><BsLink45Deg /> www.hsa-mena.com</p>
   </div>
   
    <div className='text-white flex hidden lg:flex flex-col items-center'>
    <h4 className='text-center'></h4>

    <h4 className=' font-bold'>HSA Offices</h4>
    <p> Abu Dhabi, UAE.</p>
    <p>
    Dubai, UAE</p>
    <p>
    Riyadh, Saudi Arabia.</p>
    <p>Toronto, Canada.
    </p>
    <p>New York, USA.
    </p>
    
    <p>
    Islamabad, Pakistan.</p>
  
  
   
   </div>
    </div>
      <div className='text-white text-center pb-6'>
      Copyright © 2024 HSA. All rights reserved.
      </div>
      </div>
    </div>
  )
}
{/**  
 
 */}
export default Footer6
