import React from 'react'

import aboutimg from '../images2/about.png'
const About = () => {
  return (
    <div className='text-slate-700'>
      <div className='grid xxs:grid-cols-1 lg:grid-cols-2 gap-x-6 py-6 px-6'>
        <div className=''>
          <h2 className='text-2xl font-bold uppercase'>ABOUT <span className='text-red-500'> US</span> </h2>
          <p>Welcome to Healthcare Software Alliance L.L.C., a leading provider of innovative software solutions tailored to meet the diverse needs of businesses across various industries. With a relentless focus on empowering enterprises through cutting-edge technology, we specialize
           in delivering high-impact software products that drive digital transformation and foster business growth.</p>
        </div>
    
        <div>
        <img src={aboutimg}/>
        </div>
      </div>
      <div>
      <div className='mx-6'>
      <h2 className='text-2xl font-bold' style={{ color: '#373A4A' }}>
      OUR <span className='text-red-500'>VISION</span> 
      </h2>
      <p>At Healthcare Software Alliance L.L.C., our vision is to revolutionize the way businesses operate in the digital landscape. We are dedicated to empowering organizations, both small and large-scale, with versatile software 
      solutions that streamline operations, enhance productivity, and unlock new opportunities for success.
      </p>
      </div>
        <div className='mx-6 mt-6' style={{ color: '#373A4A' }}>
          <h2 className='font-bold uppercase text-2xl'>Our <span className='text-red-500'> CORE OFFERINGS</span></h2>
        </div>
      </div>  
      <div className='grid xxs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
        
      <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 text-lg lg:text-xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>01</div>
        Empowering Distributors with Inventory Management
        </h2>
        <p>
        Our eInventory product is specifically crafted to meet the unique needs of small and large-scale distributors. From efficient stock tracking and warehousing to supply chain optimization and inventory control, our system provides distributors with the tools to streamline operations, minimize costs, and maximize efficiency in managing their inventory at any scale.
        </p> </div>
      
      
        <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 text-lg lg:text-xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>02</div>
        Transforming Financial Management
        </h2>
        <p>
        Through our cutting-edge FinTech products, we empower businesses to optimize their financial operations, gain valuable insights, and make informed decisions. From small-scale enterprises seeking streamlined invoicing and financial control to large corporations requiring advanced financial tools and risk management capabilities, 
        our FinTech solution is tailored to elevate financial management practices at any scale.</p> </div>
        

        <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 text-lg lg:text-xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>03</div>
        Elevating eCommerce Experiences
        </h2>
        <p>
        Our dynamic eCommerce platform is designed to revolutionize online retail, offering small businesses a seamless entry into the digital marketplace while providing large enterprises with a robust, customizable eCommerce infrastructure. With a focus on enhancing online presence, expanding market reach, and 
        driving sales, our eCommerce solution is the gateway to sustainable growth in the digital realm.</p>
         </div>


        
         <div className='border rounded-2xl px-6 py-6 mb-4'>
         <h2 className=' grid grid-cols-2 font-bold pb-3 text-lg lg:text-xl  uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>04</div>
         Enhancing Higher Education Management
         </h2>
         <p>
         For educational institutions, our Higher Education Management System offers a comprehensive suite of tools for academic administration and operations, student management, and institutional operations. From admissions and enrollment to academic planning and student engagement, our solution is designed to
          empower educational institutions in delivering a seamless and efficient learning experience.</p> </div>
          <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 text-lg lg:text-xl  uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>05</div>
        Enabling Data-Driven Decision Making with Analytical Dashboards 
        </h2>
        <p>
        Our Analytical Dashboards provide businesses with powerful tools for real-time monitoring, performance analysis, and informed decision-making. From visualizing key metrics to identifying trends and insights, our dashboards offer a holistic view of operations, enabling proactive decision-making and strategic planning across various business functions.</p> </div>

      </div>
      <div className='mx-6 my-6'>
      <h2 className='font-bold uppercase text-2xl' style={{ color: '#373A4A' }}>
      Our Commitment to <span className='text-red-500'>Excellence</span>
      </h2>
      <p>At the heart of our company is an unwavering commitment to excellence. We strive to deliver software products that not only meet industry standards but also exceed the expectations of our clients. From user-friendly interfaces to robust backend functionalities, our solutions are crafted with precision, innovation, and a deep understanding of business needs.
      </p>
      </div>
      <div className='py-6 px-6 mx-6 border my-6'>
      <div className='grid xxs:grid-cols-1 space-y-3 xs:grid-cols-2 lg:grid-cols-3'>
    <div className='px-6 border-r-2'>
    <h2 className='font-semibold text-red-500'>Customer-Centric Approach</h2>
    <p>
    We understand the unique challenges faced by businesses and educational institutions in today's dynamic market, and our customer-centric approach reflects our dedication to addressing those challenges. By actively engaging with our clients, understanding their requirements, 
    and providing tailored solutions, we foster strong, collaborative partnerships that drive mutual success.
    </p>
    </div>
    <div className='px-6 border-r-2'>
    <h2 className='font-semibold text-red-500'>Join Us on the Digital Journey</h2>
    <p>
    Embark on a transformative digital journey with Company Name. Whether you're a small-scale business aiming to establish a compelling online presence, a large enterprise seeking advanced financial solutions, a distributor looking to optimize inventory management, or an educational institution in need of comprehensive management tools, 
    our software products are poised to elevate your operations and fuel your business aspirations.
    </p>
    </div>
    <div className='px-6'>
    <h2 className='font-semibold text-red-500'>Contact Us to Learn More</h2>
    <p>
    Discover the power of innovative software solutions with Healthcare Software Alliance L.L.C. Get in touch with our team to explore how our eCommerce, FinTech, Inventory management,  Higher Education Management,
     and Analytical Dashboard products can reshape your business landscape and drive sustainable growth.
    </p>
    </div>
      </div>
      </div>
      
    </div>
  )
}

export default About
