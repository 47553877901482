const data = [
   
    {
        id: 1,
        Page: 'Home',
        Section: 'Home-hero',
        title: `STRONG \n TECHNOLOGY TEAMS \n FOR YOUR LONG-LASTING SUCCESS`,
        desc: `DPS has over twenty seven years ${<br/>} experience of Technology Transformation in Healthcare , Higher education and Industries sectors with Latest technology and extensive industry experience. A unique outlook on business processes to produce customized solutions for companies across the globe.`,
        img: './images/1.png'
      },
      {
        id: 2,
        Page: 'Home',
        Section: 'Home-services',
        title: 'Standalone',
        desc: `The future is change, technology is our tool and humanity our purpose. `,
        img: './images/2.png'
      },
      {
        id: 3,
        Page: 'Home',
        Section: 'Home-services-1',
        title: 'STANDALONE PHARMACIES',
        desc: `Our EzPOS & EzRX solution is designed to enhance your operations and provide a seamless experience for both your pharmacy and your patients/customers. `,
        img:'./images/3.png',
        link:'standalone'
      },
      {
        id: 4,
        Page: 'Home',
        Section: 'Home-services-2',
        title: 'GROUP OF PHARMACIES',
        desc: `Our offered solution for Group of Pharmacies is designed to enhance your operations and provide a seamless experience for both your pharmacies and your patients/customers.`,
        link:'group'
      },
      {
        id: 5,
        Page: 'Home',
        Section: 'Home-services-3',
        title: 'GROUP OF PHARMACIES WITH CENTRALIZED WAREHOUSE COMPLETE',
        desc: `Our solution is designed to enhance your operations, improve efficiency, and provide a seamless experience for both your pharmacies and your patients/customers`,
        link:'centralizedgroup'
      },
      {
        id: 6,
        Page: 'Home',
        Section: 'Home-services-4',
        title: 'DISTRIBUTORS & WHOLESALERS',
        desc: `Empower your distribution network with seamless integration and comprehensive solutions forstreamlined operations, enhanced patient care, and maximized profitability.`,
        link:'distributor'
  
      },
     
      {
        id: 7,
        Page: 'Home',
        Section: 'Home-industries-1a',
        title: 'Banking',
        desc: `In a fast-paced world of IT, businesses require a reliable partner who can bridge the gap between their technology needs and the expertise required to meet them. DPS Inc. is your trusted partner in IT Technology Staff`,
        },
      {
        id: 8,
        Page: 'Home',
        Section: 'Home-industries-1b',
        title: 'Education',
        desc: `In a fast-paced world of IT, businesses require a reliable partner who can bridge the gap between their technology needs and the expertise required to meet them. DPS Inc. is your trusted partner in IT Technology Staff`,
      },
      {
        id: 9,
        Page: 'Home',
        Section: 'Home-industries-1c',
        title: 'Food Chain',
        desc: `In a fast-paced world of IT, businesses require a reliable partner who can bridge the gap between their technology needs and the expertise required to meet them. DPS Inc. is your trusted partner in IT Technology Staff`,
        title: 'Car Rental Sector',
        desc: `We leverage cutting edge technologies to cater to your diverse business needs. With a strong focus on innovation, efficiency, and scalability,`,
     },
     
      {
        id: 10,
        Page: 'Home',
        Section: 'Home-industries-1',
        title: 'Car Rental Sector',
        desc: `We leverage cutting edge technologies to cater to your diverse business needs. With a strong focus on innovation, efficiency, and scalability,`,
  
      },
      
      {
        id: 11,
        Page: 'Home',
        Section: 'Home-products',
        title: 'PRODUCTS THAT OUR CUSTOMERS USE',
        desc: `We strive to be industry experts bringing business value and boosting your competitive power!`,
        },
      {
        id: 12,
        Page: 'Home',
        Section: 'Home-products-1',
        title: 'Campus Management System',
        desc: `We leverage cutting edge technologies to cater to your diverse business needs. With a strong focus on innovation, efficiency, and scalability,`,
        },
      {
        id: 13,
        Page: 'Home',
        Section: 'Home-products-2',
        title: 'Campus Business Intelligence',
        desc: `We leverage cutting edge technologies to cater to your diverse business needs. With a strong focus on innovation, efficiency, and scalability,`,
          },
      {
        id: 14,
        Page: 'Home',
        Section: 'Home-products-3',
        title: 'Single Sing on - (SSO)',
        desc: `We leverage cutting edge technologies to cater to your diverse business needs. With a strong focus on innovation, efficiency, and scalability,`,
         },
      {
        id: 15,
        Page: 'Home',
        Section: 'Home-contact',
        title: 'Contact Us',
        desc: ["Please feel free to drop us a line and we will contact back ASAP.",`For general inquiries`,`Call Us: +971 (2) 666 3009`,`Email Us: sales@hsa-mena.com`],
       
        img:'./images/contactimg'
        
          },
          {
            id: 16,
            Page: 'Home',
            Section: 'Home-experience',
            title: 'Contact Us',
            desc: ["DPS has over twenty seven years experience of Technology Transformation in Healthcare , Higher education and Industries sectors with Latest technology and extensive industry experience. A unique outlook on business processes to produce customized solutions for companies across the globe."],
           
            img:'./images/contactimg'
            
              },
      {
        id: 16,
        Page: 'Home',
        Section: 'Home-reviews-2',
        name: 'MATT S',
        desc: `Clean place, dryers work great. Clothes went in dirty came out clean. Mission accomplished`,
        stars:'',
        platform:'./images/google.png',
        date:'Mar 24, 2022'
      },
      {
        id: 17,
        Page: 'Home',
        Section: 'Home-reviews-3',
        name: 'ASIA SEWELL',
        desc: `I love this laundracan. It's always clean. And now they got were you can big large laundry bags in different colors and they worth it.`,
        stars:'',
        platform:'./images/google.png',
        date:'Mar 23, 2022'
      },
      {
        id: 18,
        Page: 'Home',
        Section: 'Home-reviews-4',
        name: 'REBECCA SHAHEEN',
        desc: ``,
        stars:'./images/google.png',
        platform:'./images/google.png',
        date:'Mar 20, 2022'
      },
      {
        id: 19,
        Page: 'Home',
        Section: 'Home-faqs',
        title: 'Frequently Asked Questions',
      },
      {
        id: 20,
        Page: 'Home',
        Section: 'Home-faqs-1',
        question: 'What are your hours?',
        answer: `We're open 7 days/week from 7am to 10pm.`,
      },
      {
        id: 21,
        Page: 'Home',
        Section: 'Home-faqs-2',
        question: 'What is the last wash time? ',
        answer: `The posted time for starting the last wash is 9:00 PM. This typically allows enough time for our customers to finish washing, drying and folding their clothes by 10:00 PM.`,
      },
      {
        id: 22,
        Page: 'Home',
        Section: 'Home-faqs-3',
        question: 'Is coin-less laundry really coin-less?',
        answer: `All the machines work with a laundry card – you don’t need to bring quarters. You can use cash, VISA or MasterCard to add value to a laundry card that may be re-loaded as you use up the balance on your card.`,
      },
      {
        id: 23,
        Page: 'Home',
        Section: 'Home-faqs-4',
        question: 'How do I get a new laundry card?',
        answer: `You may get a laundry card by visiting the VTM (Value Transfer Machine) at the front of the store. Follow the simple steps to get a laundry card and add value by inserting cash, VISA or MasterCard. You can add money to the card as needed. Our friendly staff is always available to assist you if you need help with your transaction.`,
      },
      {
        id: 24,
        Page: 'Home',
        Section: 'Home-faqs-5',
        question: 'How do I add money to the card?',
        answer: `You can add money to your card using cash, VISA or MasterCard at the VTM (Value Transfer Machine) next to the office. Please see the attendant if you need any assistance with your transaction. You can also register your card with Fascard by clicking here or by downloading the Fascard Mobile Apple with Apple & Android)`,
      },
      {
        id: 25,
        Page: 'Home',
        Section: 'Home-faqs-6',
        question: 'Is my laundry card reusable/reloadable?',
        answer: `Yes, the card will retain any unused balance for future visits and can be reused and reloaded as often as needed. You can reload your Fascard by clicking here or download their app in your devices app store.`,
      },{
        id: 26,
        Page: 'Home',
        Section: 'Home-faqs-7',
        question: 'How do I register my laundry card?',
        answer: `Registration can be completed by downloading the FasCard Mobile App, or by clicking here Registration is very important because it allows you to receive dicounts throughout the year and if you lose your laundry card we can trasnfer the money to a new one.`,
      },{
        id: 27,
        Page: 'Home',
        Section: 'Home-faqs-8',
        question: 'Why is the front load washer often a better choice?',
        answer: `Top load washers are smaller capacity and less efficient. Front washers provide a better wash and are gentler on your garments due to their tumbling action. They need less soap (for example a double load front load washer will need about 1 1/2 load’s worth of soap). Front load washers are better value and more environmentally friendly.`,
      },
      {
        id: 28,
        Page: 'Home',
        Section: 'Home-faqs-9',
        question: 'Can I wash my pillows in a front load washer?',
        answer: `You can – but you need to aware of a few things. Pillows can sometimes be damaged during wash and may lose stuffing or lose it’s shape. Please use your best judgment.`,
      },
      {
        id: 29,
        Page: 'Home',
        Section: 'Home-faqs-10',
        question: 'Can I wash a down comforter?Can I wash a down comforter?',
        answer: `Comforters with down fillings take longer to dry. You may need to air dry them at home to get the moisture out. Please take care to dry them completely to prevent mildew.`,
      },
      {
        id: 30,
        Page: 'Home',
        Section: 'Home-faqs-11',
        question: 'How do I add time to the dryers?',
        answer: `You can add any amount of time to the dryer by inserting your laundry card into the dryer until the desired drying time is reached. Then press the START button. Please remember to take the card with you when you are done adding time to the dryer.`,
      },
      ,
      {
        id: 31,
        Page: 'Home',
        Section: 'Home-faqs-12',
        question: 'What items should NOT be put in the dryer?',
        answer: `Anything with rubber should NOT be placed into the dryers on any temperature other than “air dry”. This includes all shoes and rugs with rubber backing, or any other item that is made with any rubber or plastic materials. They may become damaged and misshapen after being placed in the dryer. Items containing rubber or plastic are not permitted in the dryers for this reason.`,
      }

  ];
  export default data;