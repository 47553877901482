import React from 'react'

import campusimg from '../images2/campus2.png'
const campus = () => {
  return (
    <div className='text-slate-700'>
      <div className='grid h-80 product-inside grid-col-1 lg:grid-cols-2 py-6 px-6' style={{
        backgroundImage: `url(${campusimg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
       
      }}>
        <div className='text-slate-700 flex flex-col justify-center'>
          <h2 className='text-2xl font-bold uppercase'>Empower Your learning with <p></p><span className='text-red-500'>CampusTeck</span> </h2>
          <p className='xxs:text-sm xs:text-base'>Experience the next generation of clinic management with our cutting-edge solution designed to seamlessly
            integrate Electronic Medical Records(EMR), Electronic Health Records (EHR), and medical billing processes.Our Innovative HSA Clinic is tailored to meet the unique demands of modern
            healthcare practices, offering a comprehensive platform to optimize patient care and administrative efficiency.</p>
        </div>

        <div>

        </div>
      </div>
      <div>
        <div className='mx-6 mt-6' style={{ color: '#373A4A' }}>
          <h2 className='font-bold uppercase text-2xl'>campusteck <span className='text-red-500'> include</span></h2>
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
        <div className='border rounded-2xl px-6 py-6 mb-4'>
          <h2 className=' grid grid-cols-2 font-bold pb-3 flex flex-row items-center  xs:text-2xl text-lg md:text-xl xl:text-2xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>01</div>
            Enrollment
            Management
            Solution
          </h2>
          <p>
            Introducing CTeck EMS, the ultimate enrolment management solution designed to revolutionise the admissions and financial aid processes in higher education. Our comprehensive and innovative platform simplifies the enrolment journey by offering seamless online applications, dynamically generates admissions documents with pre-filled
            student information, and facilitates secure electronic signatures through trusted third-party.</p> </div>
        <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 flex flex-row items-center justify-center xs:text-2xl text-lg md:text-xl xl:text-2xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>02</div>
        CAMPUSTeck 
        CRM
        </h2>
        <p>
        CTeck CRM is the leading Student & Customer Relationship Management solution exclusively designed for higher education industry. Our comprehensive platform streamlines administrative processes, enhances student engagement, and drives sustainable growth. From improving admissions and enrolment processes to fostering efficient communication
         and collaboration, CTeck CRM empowers educational institutions to unlock their full potential.</p> </div>
        <div className='border rounded-2xl px-6 py-6 mb-4'>
        <h2 className=' grid grid-cols-2 font-bold pb-3 flex flex-row items-center xs:text-2xl text-lg md:text-xl xl:text-2xl uppercase' style={{ color: "#373A4A" }}><div className='text-8xl text-red-100 text-center'>03</div>
        Teck - Student Information System
        </h2>
        <p>
        Transform your educational institution with our advanced Student Information System (SIS). Our integrated platform simplifies administrative tasks, from enrolment, scheduling, grades and attendance, assignments to placements, while providing a comprehensive view of student data. Manage grade book, communication, appointments, enrolments, and
         student finances effortlessly. Students benefit from a dedicated portal to access grades and assignments.</p> </div>
        


      </div>
      
    </div>
  )
}

export default campus
