import React from 'react'
import Data from '../components/data'
import prodsimg from '../images/products.jpg'
import indusimg from '../images/industries.png'
import contactimg from '../images/contact.png'
import contactimg2 from '../images/contact2.jpg'
const Home = () => {
  return (
    <div>
{/**-------------------------Home-------------------------------------------- */}

    <div className="h-screen flex justify-center items-center" id="hero">
        <div className='text-center text-white w-1/2'>
     <h1 className='font-bold text-3xl'>{Data[0].title}</h1>
     <p>{Data[0].desc}</p>
     <button className='border bg-red-600 px-6 py-2 rounded-full'>View Details</button>
     </div> 
 </div>

{/**-------------------------Services-------------------------------------------- */}

    <div className="h-auto justify-center items-center py-12" style={{background:"#373A4A"}} id="services">
   <h2 className='text-center text-red-900 font-bold text-3xl'>{Data[1].title}</h2>
   <p className='text-center text-white'>{Data[1].desc}</p>
   <div className='grid grid-cols-3 py-12 px-12 gap-x-12'>
   {Data.slice(2,5).map((services)=>(
    <div className='text-center text-white border py-12 px-6'>
 <h2 className='font-bold text-2xl py-6'>{services.title}</h2>
 <p>{services.desc}</p>
 
 </div>
 ))}
   </div>
 </div>
 {/**-------------------------Industries-------------------------------------------- */}

 <div className="h-screen relative justify-center items-center pt-12" id="industries">
 
 <h2 className='text-center text-red-900 font-bold text-3xl'>{Data[5].title}</h2>
 <p className='text-center text-black'>{Data[5].desc}</p>
 <img className='absolute indusimg' src={indusimg}/>
 <div className='grid grid-cols-4 px-12 gap-x-12 indus-right'>
<div className='col-span-3'>
</div>
<div className='bg-violet-900 bg-opacity-70 flex flex-col items-center justify-center text-white px-4 py-12' style={{height:"80vh"}} >
<h2 className='font-bold text-3xl'>{Data[9].title}</h2>
<h2>{Data[9].desc}</h2>
</div>
 </div>
</div>
{/**-------------------------Products-------------------------------------------- */}

<div className="h-auto justify-center items-center relative" id="products">
<img className='absolute opacity-20' src={prodsimg} />
<h2 className='text-center text-red-900 font-bold text-3xl'>{Data[10].title}</h2>
<p className='text-center text-black'>{Data[10].desc}</p>

<div className='grid grid-cols-3 py-12 px-12 gap-x-12'>
{Data.slice(11,14).map((products)=>(
 <div className='text-center border py-12 px-6'>
<h2 className='font-bold text-2xl py-6 text-red-600'>{products.title}</h2>
<p className=' text-black'>{products.desc}</p>

</div>
))}
</div>
</div>

{/**-------------------------contact-------------------------------------------- */}

<div>
<div>
<div className='grid grid-cols-2 relative'>
<div className='text-center'>
<img src={contactimg} className='absolute contactimg'/>
<h2 className='text-red-500 font-bold text-3xl'>
Contact Us
</h2>

{Data[14].desc.map((cont)=>(<p>{cont}</p>))}

<form className=''>
      <label>Name: <br/>
        <input type="text" />
      </label>
      <br/>
      <label>Name:<br/>
        <input type="text" />
      </label>
      <br/>
      <label>Name:<br/>
        <input type="text" />
      </label><br/>
      <label>Name: <br/>
        <input type="text" />
      </label>
      <br/>
      <button className='border'>SEND MESSAGE</button>
    </form>
</div>
<div>
<img src={contactimg2} className='contactimg2' />
</div>
</div>
<div>


</div>

</div>

</div>
    </div>
  )
}





export default Home
