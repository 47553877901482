import logo from './logo.svg';
import './App.css';
import './input.css'
import Data from './components/data';
import Home from './pages/Home';
import Home2 from './pages/Home2'
import { HashRouter, 
  Routes, 
  Route } from 'react-router-dom';
  import Navbar from './components/navbar'
  import Footer2 from './components/footer2'
  import Home3 from './pages/Home3'
  import Navbar3 from './components/navbar3';
  import Home4 from './pages/Home4';
  import Footer4 from './components/footer4';
  import Navbar4 from './components/navbar4';
  import Home5 from './pages/Home5'
  import Home6 from './pages/home6'
  import Navbar6 from './components/navbar6';
  import Footer6 from './components/footer6';
  import Offering from './pages/offering';
  import Products from './pages/product';
  import Ams from './pages/ams';
  import Campus from './pages/campus';
  import Ecommerce from './pages/ecommerce';
  import Dashboard from './pages/dashboard';
  import Einventory from './pages/einventory';
  import Ezrx from './pages/ezrx';
  import Ezpos from './pages/ezpos';
  import Fintec from './pages/fintec';
  import About from './pages/about'
  import Comingsoon from './pages/comingsoon';
  import Hsa from './pages/hsa';
  import Group from './pages/group';
  import Centralized from './pages/centralized';
  import Standalone from './pages/standalone';
  import Scrolltotop from './components/scrolltotop';
  import Warehouse from './pages/warehouse';
  import Tatmeen from './pages/tatmeen';
  import Printerr from './pages/printerr';
  import Dropdown from './components/dropdown';
  import Tatmeenonboarding from './pages/tatmeenonboarding';
 
  
  import { useRef } from 'react';
 
function App() {
   
  return (
    
    <div className="">
 {/**<Navbar4 />
<Navbar3 />

     <Navbar /> */}
    
     <Dropdown />
     <Scrolltotop />
      <Routes>
      
        <Route path="/home" element={<Home />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/home3" element={<Home3 />} />
        <Route path="/home4" element={<Home4 />} />
        <Route path="/home5" element={<Home5 />} />
        <Route path="/" element={<Home6 />} />
        <Route path="/offering" element={<Offering />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/ams" element={<Ams />} />
        <Route path="/products/campusteck" element={<Campus />} />
        <Route path="/products/ecommerce" element={<Ecommerce />} />
        <Route path="/products/dashboard" element={<Dashboard />} />
        <Route path="/products/einventory" element={<Einventory />} />
        <Route path="/products/hsa" element={<Hsa />} />
       <Route path="/products/ezrx" element={<Ezrx />} />
        <Route path="/products/ezpos" element={<Ezpos />} /> 
        <Route path="/products/fintech" element={<Fintec />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/offering/group" element={<Group />} />
        <Route path="/offering/centralizedgroup" element={<Centralized />} />
        <Route path="/offering/standalone" element={<Standalone />} />
        <Route path="/offering/distributor" element={<Warehouse />} />
        <Route path="/tatmeen" element={<Tatmeen />} />
        <Route path="/printerr" element={<Printerr />} />
        <Route path="/tatmeenonboarding" element={<Tatmeenonboarding />} />
     
      </Routes> 
    {/**
   <Footer2/> 
   <Footer4/>  */}
   <Footer6/>
    </div>
  );
}

export default App;
