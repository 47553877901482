import React from 'react'

const printerr = () => {
  return (
    <div>
    <div style="vertical-align:bottom; box-shadow: inset 0 0 0 1px black;">
                    <table cellpadding="5" cellspacing="0" style={{marginLeft:"13px"}} width="98%">
                        <tr>
                            <td align="left" valign="top">
                                <span style={{paddingLeft:"188px"}}>For <b>SmartCare LLC</b></span>
                                <br />
                                <p style={{width:"52%"}}><b>1 </b> Goods once sold will not be replaced or exhanged, please check all goods on delivery</p>
                                <span style={{marginLeft: "47%"}}><b> Remarks :  </b></span><br />
                                <textarea cellspacing="0" style={{float:"right",marginTop: "-24px",marginRight: "9px", width: "40%"}} rows="4" cols="50"></textarea>
                                <p style={{width:"409px", whiteSpace:"normal"}}><b> 2 </b>This Signature confirms Buyer's Acceptance to above transaction in case official purchase order is not issued by the customer</p>
                                <br />
                                <b> Reciever's Name & Company Stamp :  </b>______________
                                <span align="right"><b style={{ marginLeft:"27px"}}> Signature :  </b>______________</span>
                            </td>
                        </tr>
                    </table>
                </div>
    </div>  
  )
}

export default printerr
