import React from 'react'
import centralizedimg from '../images2/centralized.jpg'
const centralized = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid grid-cols-1 lg:grid-cols-2 my-6 mx-6'>
    <div className='flex flex-col justify-center'>
      <h2 className='text-2xl lg:text-3xl font-bold uppercase'>Our Pharmacy Solution Offering for Group of Pharmacies having <span className='text-red-500'>Centralized Warehouse </span></h2>
      <p className='md:text-base'>We understand the unique requirements of medium pharmacy businesses with multiple interconnected pharmacies and a centralized warehouse. We are dedicated to providing a comprehensive solution that caters to your specific needs. Our products and features are designed to streamline your operations, enhance efficiency, and improve your customer service.</p>
       </div>
       <div>
       <img src={centralizedimg}/>
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='font-bold  text-xl lg:text-3xl uppercase'>Products and <span className='text-red-500'>Features</span> </h2>
       <p className='sm:text-sm md:text-base'>Our RSD (KSA) and Tatmeen (UAE) compliant EzPOS & EzRX, eInventory & AMS solution is a powerful tool that empowers medium pharmacy businesses with a range of
        essential features to optimize their operations. The following key components are included:</p>
       </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       EzPOS - Point of Sale (POS) of Multiple Pharmacies
       </h2>
       <p>
       Our POS system is tailored to meet the specific needs of medium pharmacy businesses with multiple interconnected pharmacies. It provides a seamless checkout process and efficient transaction management across all your pharmacies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
 eInventory – Inventory Management System
       </h2>
       <p>
       Our eInventory product allows you to efficiently manage the inventory of all your interconnected pharmacies. It ensures optimal stock levels, streamlined supply chain management, and real-time inventory tracking.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Patient Management
       </h2>
       <p>
       Streamline patient records, prescription history, and communication with our integrated patient management
        system.
        This feature enhances customer service and care across all your interconnected pharmacies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Sales and Inventory Optimization Dashboards and Analytics
       </h2>
       <p>
       Gain valuable insights into your sales and inventory performance with our intuitive dashboards and analytical reports. This feature helps you make data-driven decisions and optimize your operations.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Centralized Reporting Module
       </h2>
       <p>
       Our centralized reporting module allows you to generate comprehensive reports across all your interconnected pharmacies. This feature provides valuable insights into your business performance and helps you identify areas for improvement.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       EzRx - Claim & Remittance Management
       </h2>
       <p>
       Simplify the process of managing health insurance claims and remittances across all your interconnected pharmacies. This feature ensures hassle-free interactions with leading health insurance companies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Accounts Management System
       </h2>
       <p>
       Our solution includes robust accounts management capabilities, allowing you to handle financial transactions, reporting, and reconciliation within each pharmacy.
       
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       AMS - Centralized Accounts Management System
       </h2>
       <p>
       Our solution includes a centralized accounts management system that allows you to handle financial transactions, reporting, and reconciliation across all your interconnected pharmacies.
       
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration with RSD (KSA) and Tatmeen (UAE)
       </h2>
       <p>
       Seamlessly integrate with RSD (KSA) and Tatmeen (UAE) to ensure compliance and efficient data exchange. 
       This integration enhances operational efficiency and regulatory compliance.
       </p>
       </div>
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS FOR GROUP OF PHARMACIES HAVING CENTRALIZED WAREHOUSE</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Comprehensive 
Solution
       </h2>
       <p>
       Our solution offers a comprehensive set of features tailored to the unique needs of pharmacy businesses with multiple interconnected pharmacies and a centralized warehouse. It covers essential aspects of operations, patient care, inventory management, financial management, Dashboards and reporting.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Efficiency and Streamlining 
       </h2>
       <p>
       By integrating point of sale, inventory management, accounts management, patient management, and reporting, our solution enables you to streamline your operations and improve overall efficiency across all your interconnected pharmacies.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance       
       </h2>
       <p>
       Integrated with local health authorities ADHICS, Malaffi, Tatmeen (UAE) and RSD (KSA),our solution ensures
        compliance with regulations and facilitates seamless data exchange for enhanced operational efficiency</p>
       </div>
       
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Data-Driven Decision Making 
       </h2>
       <p>
       Our sales and inventory optimization dashboards and analytical reports provide valuable insights into your business performance. This helps you make data-driven decisions and optimize your operations for better results.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Hassle-free Claim Management
       </h2>
       <p>
       The inclusion of claim and remittance management simplifies interactions with health insurance companies, reducing administrative burden and ensuring efficient payer management across all your interconnected pharmacies.</p>
       </div>
       </div>
       <p className='m-6'>We are committed to empowering pharmacy businesses with the tools and capabilities they need to thrive in a competitive healthcare landscape. Our solution is designed to enhance your operations, improve efficiency, and provide a seamless 
       experience for both your pharmacies and your patients/customers.</p>
       </div>
       </div>
  )
}

export default centralized
