import React from 'react'
import Data from '../components/data'
import prodsimg from '../images/products.jpg'
import indusimg from '../images/industries.png'
import contactimg from '../images/mountain.webp'
import groupimg from '../images/group1.png'
import contactimg2 from '../images2/map2.png'
import hero from '../images/tech9.jpg'
import partner from '../images/Logo 1.png'
import partner2 from '../images/Logo 2.png'
import partner3 from '../images/Logo 3.png'
import partner4 from '../images/Logo 4.png'
import partner5 from '../images/Logo 5.png'
import partner6 from '../images/rsd-2.png'
import partner7 from '../images/Logo 7.png'
import banking from '../images/industryicons/banking.svg'
import auto from '../images/industryicons/auto.svg'
import retail from '../images/industryicons/retail.svg'
import healthcare from '../images/industryicons/healthcare.svg'
import education from '../images/industryicons/education.svg'
import realestate from '../images/industryicons/realestate.svg'
import logistics from '../images/industryicons/logistics.svg'
import construction from '../images/industryicons/construction.svg'
import { SiPaloaltosoftware } from "react-icons/si";
import cover from '../images2/cover.png'
import partnerimg from '../images2/part.png'
import partnerimg2 from '../images2/part2.png'
import partnerimg3 from '../images2/part3.png'
import partnerimg4 from '../images2/part4.png'
import partnerimg5 from '../images2/part5.png'
import partnerimg6 from '../images2/part6.png'
import partnerimg7 from '../images2/part7.png'
import partnerimg8 from '../images2/part8.png'
import ParticleBackground from '../components/particleBackground'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import arrow from '../images/arrow.png'
import arrow2 from '../images/arrow2.png'
import { MdOutlinePointOfSale } from "react-icons/md";
import { BiClinic } from "react-icons/bi";
import { MdOutlineInventory } from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import { BiTransfer } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { MdAccountBalance } from "react-icons/md";
import { RiGovernmentLine } from "react-icons/ri";
import { TbBusinessplan } from "react-icons/tb";
import { IoCartOutline } from "react-icons/io5";
import { BsBoxes } from "react-icons/bs";

const Home6 = () => {
  return (
    <div>
      {/**---------------------------------------------Hero-------------------------------------------- */}

      <div className="h-96 flex xs:items-center px-8" id='hero6'>


        <div className='grid xxs:content-center sm:grid-cols-1 sm:content-center md:grid-cols-2 h-98'>

          <div className=''>
            <h3 className='text-red-500 xs:text-base sm:text-lg md:text-2xl lg:text-3xl font-bold'>WELCOME TO</h3>
            <h1 className=' xs:text-xl sm:text-2xl md:text-3xl mb-4 lg:text-4xl xl:text-5xl text-slate-700 font-bold'>HEALTHCARE </h1>
            <h1 className='xs:text-xl sm:text-2xl md:text-3xl mb-4 lg:text-4xl xl:text-5xl text-slate-700 font-bold'> SOFTWARE ALLIANCE</h1>

            {/**<h1 className='font-bold '>{Data[0].title}</h1>*/}
            {/** 
     <p>{Data[0].desc}</p>*/}
            <h7 className='text-justify xs:text-base sm:text-lg md:text-xl lg:text-2xl text-slate-700'>
              Your Success, Our Priority: Uniting Through Comprehensive Solutions

            </h7>
            <br />
            <br />
            {/**hover:bg-white hover:text-slate-700 hover:border-red-500 hover:border-2 */}

            <Link to="/tatmeenonboarding" className='btn-hero hover:bg-white hover:text-slate-700 hover:border-rose-700 hover:border-2  xxs:text-xs xs:text-sm md:text-base border px-6 py-5 rounded-full text-white cursor-pointer font-bold bg-rose-700'>Tatmeen On-boarding</Link>

          </div>
          <div className='relative m-auto'>

            <img style={{ width: "50%" }} />
            <div className='absolute top-1/2 left-1/2 transform -translate-x-3/4 -translate-y-3/4" bg-white blur-2xl' style={{ width: "50vw", height: "25px" }}></div>
          </div>

        </div>

      </div>

      {/**-------------------------Partners-------------------------------------------- */}
      {/**
 <div className='partners flex justify-between items-center py-7 bg-zinc-50 px-8 overflow-hidden' id="partners">
 <img src={partnerimg5} className='px-2'  />
 <img src={partnerimg6} className='w-24 px-2'/>
 <img src={partnerimg} className='px-2'/>
 <img src={partnerimg2} className='px-2'   />
 <img src={partnerimg3} className='px-2'   />
 <img src={partnerimg4} className='px-2'   />
 
 <img src={partnerimg7} className='px-2'   />
 </div> */}

      <div class="bg-zinc-50">
        <div class="brand-wheel">
          <div class="brand-slide space-x-12">
            <div class="logo-div">
              <img alt="#" src={partnerimg} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg2} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg3} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg4} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg5} />
            </div>
            <div class="logo-div w-24">
              <img alt="#" src={partnerimg6} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg7} />
            </div>

            <div class="logo-div">
              <img alt="#" src={partnerimg} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg2} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg3} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg4} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg5} />
            </div>
            <div class="logo-div w-24">
              <img alt="#" src={partnerimg6} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg7} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg2} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg3} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg4} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg5} />
            </div>
            <div class="logo-div w-24">
              <img alt="#" src={partnerimg6} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg7} />
            </div>

            <div class="logo-div">
              <img alt="#" src={partnerimg} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg2} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg3} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg4} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg5} />
            </div>
            <div class="logo-div w-24">
              <img alt="#" src={partnerimg6} />
            </div>
            <div class="logo-div">
              <img alt="#" src={partnerimg7} />
            </div>
          </div>
        </div>
      </div>
      {/**-------------------------Quick Overview--------------------------------------------  */}
      <div style={{ color: '#373A4A' }} className='px-8 pt-10 bg-zinc-50'>

        <div className='grid grid-cols-1 lg:grid-cols-2 space-x-6 text-justify'>
          <div className=''>
            <h2 className='text-2xl lg:text-3xl font-bold uppercase leading-8'>WHO WE <span className=' text-red-500'> ARE</span></h2>
            <p className='leading-6 text-sm lg:text-base'>{/**We collaborate closely with healthcare, medical, life science, pharmaceutical, and wellness organizations, developing custom software solutions to improve healthcare outcomes.

          We are committed to providing our clients with technical expertise and domain knowledge by harnessing cutting-edge technologies to significantly enhance medical results and fortify community health networks. Our specialization lies in developing, integrating, and enhancing custom healthcare software solutions that deliver substantial value to our clientele.
           a leading provider of innovative software solutions tailored to meet the diverse needs of businesses across various industries. With a relentless focus on empowering enterprises through cutting-edge technology, we specialize
           in delivering high-impact software products that drive digital transformation and foster business growth. */}
              For over ten years, Healthcare Software Alliance, a DPS Company, has been at the forefront of
              combining cutting-edge technology with deep industry expertise to deliver customized software
              solutions to healthcare companies worldwide. Our mission is to provide
              high-quality business technology solutions that help our clients maximize their IT investment
              returns.<p></p> {/**With a footprint of 
              700+ & 200+ Pharmacies in UAE and
             Saudi Arabia */}<br/>
              
               With a robust customer base of over 700 retail pharmacies across the UAE and an additional 200 in KSA.
              Our Health-care Solutions has established itself as a beacon of innovation and efficiency. Our comprehensive
              suite of software products is designed to
              streamline operations, improve profit margins and optimize the management of pharmaceutical inventories.
 
            </p>
           
          </div>

          <div className=' rounded-3xl grid grid-cols-2 xs:grid-cols-3'>
          <div className='flex flex-col  border h-32 lg:h-44 justify-center items-center'>
              
              <img src={partnerimg8} className='h-28 lg:h-36'/></div>
            <div className='flex flex-col border h-32 lg:h-44 justify-center items-center'>
              <h2 className='font-bold'>Experience</h2>
              <p className='text-3xl xs:text-5xl font-bold text-red-500'>10+<span className='text-slate-700 text-xs'>(years)</span></p>
            </div>
            <div className='flex flex-col border h-32 lg:h-44 justify-center items-center'>
              <h2 className='font-bold '>Countries</h2>
              <p className='text-3xl xs:text-5xl font-bold  text-red-500'>2</p></div>
            <div className='flex flex-col border h-32 lg:h-44 justify-center items-center'>
              <h2 className='font-bold'>Pharmacies</h2>
              <p className='text-3xl xs:text-5xl font-bold  text-red-500'>900+</p></div>
            <div className='flex flex-col  border h-32 lg:h-44 justify-center items-center'>
              <h2 className='font-bold'>Distributors</h2>
              <p className='text-3xl xs:text-5xl font-bold  text-red-500'>7</p></div>
            
            <div className='flex flex-col  border h-32 lg:h-44 justify-center items-center'>
              <h2 className='font-bold text-center'>Group of Pharmacies</h2>
              <p className='text-3xl xs:text-5xl font-bold  text-red-500'>30+</p></div>

          </div></div>
          <h2 className='text-2xl lg:text-3xl font-bold rounded-full uppercase text-white  py-12 mt-6 w-full text-center bg-slate-700 leading-8'>NOW CONNECTED WITH <span className=''> MALAFFI e-PRESCRIPTION</span></h2>

      </div>

      {/**------------------------------------Services-------------------------------------------- */}

      <div className="px-8 pt-10 relative justify-center items-center bg-zinc-50" id="products">
        <div className='pb-4'>
          <h2 className='text-slate-700 lg:text-3xl md:text-2xl xs:text-base font-semibold'>OUR <span className='text-red-500'> SERVICES</span></h2>
          <p className='text-slate-700 md:text-sm lg:text-base font-semibold'>We pride ourselves on offering a diverse healthcare product portfolio, continually innovating and developing top-tier solutions to meet both the current and future technology needs of our customers. We are committed to helping
            you achieve your business goals with tailored, effective, and forward-thinking software solutions.

          </p>
        </div>


        <div className='grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 grid text-slate-700 pt-4'>
          <div className='border py-8 px-1 xs:px-3 rounded  text-left' >
            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className='bg-red-500 text-white px-1 xs:px-2 py-2'>
              <MdOutlinePointOfSale className='text-2xl' /></div>
              {/**<p className='text-5xl text-red-100 font-bold'>01</p>*/}
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Pharmacy Retail Point of Sales</h4></div>

            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Streamline transactions and enhance customer service and insurance claim management
              with our efficient and user-friendly POS system tailored for pharmacies.</p>



          </div>

          
          <div className='border py-8 lg:mt-0 px-1 xs:px-3 rounded text-left h-auto'>
            {/**
   <MdOutlineInventory className='text-5xl' /> */}
            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className='bg-red-500 text-white px-1 xs:px-2 py-2'>
            <BsBoxes className='text-2xl' /></div>
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Inventory Management System {/**for Standalone, Warehouse, Distributors & Drug stores: */}  </h4>
            </div>

            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Optimize stock levels and ensure accurate inventory tracking across various business setups with our comprehensive inventory management solution.
            </p>

          </div>
          <div className='border py-8 px-1 xs:px-3 rounded text-left h-auto'>
          {/**<MdAccountBalance className="text-5xl"/>*/}
          <div className='flex items-center space-x-1 xs:space-x-2'>
          <div className="bg-red-500 text-white px-1 xs:px-2 py-2">
            <MdAccountBalance className="text-2xl" /></div>
            <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Accounting Management System</h4></div>
          <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Manage financial operations with ease using our accounting system designed for both standalone pharmacies and group setups.
          </p>

        </div>
          
          <div className='border py-8 px-1 xs:px-3 rounded text-left  h-auto'>
            {/**<BiTransfer className='text-5xl'text-sm bg-red-300 text-white px-1 xs:px-2 py-2/  style={{height:"50px", width:"50px"}}>*/}
            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className='bg-red-500 flex items-center justify-center text-white px-1 xs:px-2 py-2'>
              <BiTransfer className='text-2xl'/></div>
<div>
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Purchases, Sales & Transfer Management</h4>
              </div>
              </div>
            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>{/** Are you looking for a comprehensive solution to manage your Pharmacy retail business efficiently?  Look no further! */}Simplify and automate the processes of
              purchasing, selling, and transferring stock to maintain optimized inventory level in all pharmacies.
            </p>

          </div>
          
          <div className='border py-8 px-1 xs:px-3 rounded text-left h-auto'>
            {/**<BsCashCoin className='text-5xl' />*/}
            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className='bg-red-500 text-white px-1 xs:px-2 py-2'>
              <BsCashCoin className='text-2xl'/></div>
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Insurance Claims and Remittance</h4></div>
            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Efficiently handle insurance claims and remittances
              for standalone pharmacies and centralized setups for groups of pharmacies.
            </p>

          </div>
          <div className='border py-8 px-1 xs:px-3 rounded text-left h-auto' >
            {/**<SiSimpleanalytics className="text-5xl" />*/}

            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className="bg-red-500 text-white px-1 xs:px-2 py-2">
              <SiSimpleanalytics className="text-2xl"/></div>
              {/**<p className='text-5xl text-red-100 font-bold'>04</p>*/}
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Centralized Reporting & Dashboard Analytics </h4>
            </div>
            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'> Gain valuable insights and make data-driven decisions with our centralized reporting and analytics dashboards.
            </p>


          </div>
          <div className='border py-8 px-1 xs:px-3 rounded text-left h-auto'>
            {/** <RiGovernmentLine className='text-5xl'/>*/}
            <div className='flex items-center space-x-1 xs:space-x-2 '>
          <div className='bg-red-500 text-white px-1 xs:px-2 py-2'>
              <RiGovernmentLine className='text-2xl' /></div>
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Integration with UAE & KSA Health Authorities </h4>
            </div>
            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Seamlessly connect with major healthcare regulator’s systems
              in UAE and KSA to ensure compliance and improve operational efficiency.
            </p>

          </div>
          <div className='border py-8 px-1 xs:px-3 rounded text-left h-auto'>
            {/** <BiClinic className="text-5xl" />*/}
            <div className='flex items-center space-x-1 xs:space-x-2'>
            <div className='bg-red-500 flex items-center justify-center text-white px-1 xs:px-2 py-2'>
              <BiClinic className='text-2xl' />
              </div>
              <div>
              <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>HSA Clinic Electronic Health Record (EHR) </h4>
              </div>
              </div>
            <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Enhance patient care and clinic efficiency with our electronic medical records and health records (EHR) solution for clinics.
            </p>

          </div>
          <div className='border py-8 px-1 xs:px-2 rounded text-left h-auto'>
          {/**<TbBusinessplan className="text-5xl" />*/}
          <div className='flex items-center space-x-1 xs:space-x-2'>
          <div className='bg-red-500 flex items-center justify-center text-white px-1 xs:px-2 py-2'>
          <IoCartOutline className="text-2xl"  />   </div>
            <h4 className='font-semibold uppercase text-xs sm:text-sm md:text-base xl:text-xl'>Healthcare eCommerce Platform</h4>
            </div>
          <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Expand your reach and boost
            sales with our eCommerce platform, suitable for both standalone pharmacies and group setups. </p>
        </div>
        </div>


      </div>
      {/**-------------------------------------Products-------------------------------------------- 

<div className="px-8 py-6 relative justify-center items-center bg-zinc-50" id="products">
 <div className='pb-4'>
<h2 className='text-slate-700 lg:text-3xl md:text-2xl xs:text-base font-semibold'>OUR <span className='text-red-500'> PRODUCTS</span></h2>
<p className='text-slate-700 md:text-sm lg:text-base font-semibold'>A good pharmacy system with good processes
should help you manage all of these areas efficiently and effectively.

</p>
</div>


<div className='grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 grid text-slate-700'>
<Link className='relative border py-8 px-3 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40' style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}}  to="/products/hsa" >
    
    <h4 className='font-semibold'>HSA Clinic</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Unlock the potential of seamless EMR/EHR management and efficient 
    medical billing with our 
    comprehensive Clinic Management System.</p>
   <img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    
    <img className='absolute bottom-0  w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    </Link>
   
    <Link className='relative border py-8 px-3 rounded xxs:text-center xs:text-left cursor-pointer product xxs:h-24 xs:h-40' to="/products/dashboard" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}}   >
    
    <h4 className='font-semibold'>Dashboard</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Embrace a data-driven approach to business optimization with our dynamic Analytical Dashboard.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
   
    </Link>
    <Link className='relative border py-8 lg:mt-0 px-3 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40' style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} to="/products/einventory" >
    
    <h4 className='font-semibold'>eInventory</h4>
    <p  className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Take the next step towards efficiency, accuracy, and success with our cutting-edge eInventory Product.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
   
    </Link>
    
    <Link className='relative border py-8 px-3 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40'  to="/products/ecommerce"  style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}}  >
    
    <h4 className='font-semibold'>HSA E-Commerce</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Elevate your online presence and drive business growth with our dynamic eCommerce solution.
    </p> <img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
   
    </Link>
    <Link className='relative border py-8 px-3 cursor-pointer product rounded xxs:text-center xs:text-left  xxs:h-24 xs:h-40'  to="/products/ezpos" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} >
    
    <h4 className='font-semibold'>EzPOS</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>{/** Are you looking for a comprehensive solution to manage your Pharmacy retail business efficiently?  Look no further! Our EzPOS product is designed to meet the specific needs of a pharmacies in medicine and general items sales.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    
    </Link>
    <Link className='relative border py-8 px-2 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40'  to="/products/ezrx" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} >
    <h4 className='font-semibold'>EzRX</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Experience the difference that our Health Insurance Claim Management Software can make for your organization.<span className='xs:hidden sm:inline'> Streamline your operations, improve efficiency, and enhance profitability.</span>
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    </Link>
    <Link className='relative border py-8 px-3 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40'  to="/products/ams" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} >
    
    <h4 className='font-semibold'>AMS</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Simplify financial oversight and control with our comprehensive Accounts Management System.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    
    </Link>
    <Link className='relative border py-8 px-4 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40'  to="/products/campusteck" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} >
    
    <h4 className='font-semibold'>CampusTeck</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>One-Stop Shop for all of your Campus Needs
    Enrollment Management Solution, CRM Student Portal, Student Information System, System Integration & Development.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    
    </Link>
    <Link className='relative border py-8 px-3 cursor-pointer product rounded xxs:text-center xs:text-left xxs:h-24 xs:h-40'  to="/products/fintech" style={{backgroundColor:"rgba(55, 58, 74, 0.05)",border:"1px solid rgba(55, 58, 74, 0.2)"}} >
    
    <h4 className='font-semibold'>FinTech</h4>
    <p className='xxs:hidden xs:block text-sm xs:text-xs xl:text-base'>Drive financial excellence and strategic decision-making with our dynamic FinTech solution.
    </p><img className='absolute bottom-0 w-8 md:w-12 lg:w-14 right-0' src={arrow} />
    
    </Link>
</div>


</div>*/}


      {/**------------------------------------Offerings-------------------------------------------- */}

      <div className="h-auto justify-center bg-zinc-50 items-center pt-10 px-8" id="offering">

        <div className=''><h2 className=' text-slate-700 mb-2  lg:text-3xl md:text-2xl xs:text-base font-semibold'>OUR <span className='text-red-500'>OFFERINGS</span> </h2>
          <p className='text-center text-black md:text-sm lg:text-base text-justify font-semibold text-slate-700'>We strive to be industry experts
            bringing business value and boosting your competitive power.</p></div>
        <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pb-6 pt-4 gap-x-4' style={{ color: '#373A4A' }}>
          {Data.slice(2, 6).map((offering) => (
            <Link className='shadow-3xl relative bg-white pb-12 pt-2 px-6 my-2 border offering-card' to={`/offering/${offering.link}`}>

              <h2 className='py-6 text-lg lg:text-2xl font-extrabold'>{offering.title}</h2>

              <p className='text-sm md:text-base'>{offering.desc}</p>
              <div className='absolute flex w-full items-center learn bottom-0 font-bold my-2 text-sm md:text-base'>Learn More
                <img src={arrow2} className='mx-2 w-4 sm:w-4 md:w-6' />
              </div>
            </Link>
          ))}
        </div>
      </div>






    </div>
  )
}


export default Home6                                                                                                                                                                                            
