import React from 'react'
import dashboardimg from '../images2/dashboard.jpg'
import offeringsbg from '../images2/offering-bg.png'
const dashboard = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid h-auto product-inside grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${dashboardimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='flex flex-col justify-center'>
      <h2 className='xs:text-lg sm:text-2xl font-bold uppercase text-slate-700'>Gain Actionable 
      Insights<p></p> with Our <span className='text-red-500'>Analytical<p></p> Dashboard</span> </h2>
      <p className='text-sm md:text-base '>Experience the power of data-driven decision-making with our dynamic 
      Analytical Dashboard, offering 
      comprehensive visualizations of key business systems. From Inventory Management to FinTech,
       Higher Education ERP
      , eCommerce, Sales, and Procurements products, our dashboard provides intuitive and interactive
       representations, empowering you to monitor, analyze, and optimize diverse aspects of your operations.</p>
       </div>

       <div>
      
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='text-slate-700 mt-6 text-2xl font-bold'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       	Multi-System Integration 
       </h2>
       <p>
       Access real-time visualizations and analytics across multiple systems, consolidating data from
        Inventory Management, FinTech, Higher Education ERP, eCommerce, Sales, and Procurement platforms. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customizable Dashboards 
       </h2>
       <p>
       Tailor the dashboard to your specific business needs, allowing personalized views, 
       drill-down capabilities, and interactive charts for in-depth exploration of critical metrics. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Predictive Analytics 
       </h2>
       <p>
       Leverage advanced forecasting and predictive modeling tools to 
       anticipate trends, identify opportunities, and mitigate risks across various operational domains.   </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Performance Monitoring 
       </h2>
       <p>
       Track KPIs, metrics, and performance indicators across
        different business functions, facilitating proactive decision-making and continuous improvement.  </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Data Accessibility 
       </h2>
       <p>
       Empower stakeholders with secure, role-based access to relevant 
       visualizations and reports, fostering collaboration and informed decision-making at all levels.</p>
       </div>
       
       
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS FOR STANDALONE PHARMACIES</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Holistic Business Insights 
       </h2>
       <p>
       Gain a holistic view of your organization's performance, from inventory optimization
        to financial transactions, academic operations, online sales, and procurement activities </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Operational Efficiency
       </h2>
       <p>
       Identify bottlenecks, streamline processes, and optimize
        resource allocation based on actionable insights derived from the integrated visualizations. </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Strategic Planning 
       </h2>
       <p>
       Align business strategies, resource allocation, and growth initiatives
        with data-backed insights, enabling strategic planning and informed decision-making.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance
       </h2>
       <p>
       Stay abreast of healthcare regulations and compliance standards, mitigating risksand ensuring adherence to industry requirements.</p>
       </div>
       </div>
       <h2 className='mt-6 mx-6 text-red-500 font-bold'>
       Experience the Power of Data Visualization
       </h2>
       <p className='mx-6'>Unlock the potential of comprehensive visualizations that transcend individual systems, providing a unified perspective on your organization's diverse operations. Our Analytical Dashboard is your gateway to actionable insights and informed decision-making across
        Inventory Management, FinTech, Higher Education ERP, eCommerce, Sales, and Procurements.</p>
        <p className='p-6'>Embrace a data-driven approach to business optimization with our dynamic Analytical Dashboard.</p>
       </div>
       </div>
  )
}

export default dashboard
