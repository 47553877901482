import React from 'react'
import offeringsimg from '../images2/standalone.jpg'
import offeringsbg from '../images2/offering-bg.png'
const Standalone = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid grid-cols-1 lg:grid-cols-2 my-6 mx-6'>
    <div className='flex flex-col justify-center' style={{color:'#373A4A'}}>
      <h2 className='text-2xl font-bold'>STANDALONE</h2>
      <p className=''>We understand the unique requirements of standalone pharmacies and small businesses. We are dedicated 
      to providing comprehensive solutions that cater to your specific needs. Our
       products and features are tailored to streamline pharmacy operations and enhance your business efficiency.</p>
       </div>
       <div className=''>
       <img src={offeringsimg}/>
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='font-bold text-2xl'> OFFERED PRODUCTS & <span className='text-red-500'>FEATURES IN STANDALONE</span></h2>
       <p>Our RSD (KSA) and Tatmeen (UAE) compliant EzPOS & EzRX solution is designed to empower standalone 
       pharmacies 
       with a range of essential features to optimize their operations. The following key components are included:</p>
       </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       EzPOS - Point of Sale
       </h2>
       <p>
       Our EzPOS is tailored to meet the specific
        needs of pharmacies, providing a  seamless Sales process and efficient transaction management.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Patient Management
       </h2>
       <p>
       Streamline patient records, prescription history, and communication with our integrated patient management system,
        designed to enhance customer service and care.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Inventory Management
       </h2>
       <p>
       Efficiently manage your inventory, including pharmaceuticals and general items, to ensure optimal stock
        levels and streamlined 
       supply chain management.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       EzRx - Claim & Remittance Management
       </h2>
       <p>
       Simplify the process of managing health insurance claims and remittances, ensuring hassle-free interactions with leading health
        insurance companies.       
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Accounts Management
       </h2>
       <p>
       Our solution includes robust accounts management capabilities, allowing you to handle financial transactions, reporting, and reconciliation 
       with ease.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration with RSD (KSA) and Tatmeen (UAE)
       </h2>
       <p>
       Seamless integration with the local health authorities and RSD
(KSA) and Tatmeen (UAE)  to ensure compliance and efficient data exchange.
       </p>
       </div>
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6 text-2xl'>BENEFITS FOR STANDALONE PHARMACIES</h2>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Comprehensive 
Solution
       </h2>
       <p>
       Our EzPOS & EzRX solution offers a comprehensive set of features tailored
        to the unique needs of standalone pharmacies, covering essential aspects of operations and patient care.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Efficiency and Streamlining
       </h2>
       <p>
       By integrating point of sale, patient management, inventory management, and accounts 
       management, our solution enables pharmacies to streamline their operations and improve overall efficiency.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance
       </h2>
       <p>
       Integrated with local health authorities ADHICS, Malaffi, Tatmeen (UAE) and RSD (KSA),our solution ensures
        compliance with regulations and facilitates seamless data exchange for enhanced operational efficiency.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Enhanced 
Customer Service
       </h2>
       <p>
       Improved patient management capabilities allow
        for better customer service and     care, ensuring a positive experience for your pharmacy's clientele.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Hassle-free Claim Management
       </h2>
       <p>
       The inclusion of claim and remittance management simplifies interactions with health insurance companies, reducing administrative burden and ensuring efficient payer management.</p>
       </div>
       </div>
       <p className='m-6'>We are committed to empowering standalone pharmacies with the tools and capabilities they need to thrive
        in a competitive healthcare landscape. Our EzPOS & EzRX solution is designed to 
       enhance your operations and provide a seamless experience for both your pharmacy and your patients/customers.</p>
       </div>
       </div>
  )
}

export default Standalone
