import React from 'react'
import ezposimg from '../images2/ecommerce.png'
import offeringsbg from '../images2/offering-bg.png'
const ezpos = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid product-inside h-80 grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${ezposimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='text-slate-700 flex flex-col justify-center'>
      <h2 className='text-2xl font-bold uppercase'>Introducing our  <span className='text-red-500'>EzPOS</span>, <p className='hidden lg:block'></p>a Pharmacy Retail Point of  <p className='hidden lg:block'></p>Sales Software</h2>
      <p>With advanced features and seamless integration with local health authorities in UAE and KSA, our software is the perfect choice for your business.</p>
       </div>

       <div>
      
       </div>
       </div>
       
       <div className='mx-6'>
       <h2 className='text-red-500 font-bold text-2xl mt-12 text-slate-700'>KEY <span className='text-red-500 '>FEATURES</span></h2>
        </div>
      
       <div className='m-6'>
       <h2 className='font-semibold'>1.	Pharmacy POS </h2>
       <p>Our software includes a dedicated Pharmacy POS module that caters to the unique requirements of pharmacies. It enables you to digitally and electronically
        accept payments, track available inventory, manage customers, and streamline your pharmacy operations.</p>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 md:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Real-time Inventory Tracking
       </h2>
       <p>
       Our software provides real-time visibility into your inventory, allowing you to monitor stock levels, 
       track product movements, and avoid stockouts or overstock situations.</p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Automated Reordering
       </h2>
       <p>
       Set up automated reordering triggers based on predefined stock levels,
        ensuring that you never run out of essential products.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
      	Batch and Expiry Tracking
       </h2>
       <p>
       For pharmacies and businesses dealing with perishable items,
        our software includes batch and expiry tracking features to manage product shelf life effectively.   </p>
       </div>
       </div>

       <div className='m-6'>
       <h2 className='font-semibold'>2.	Accounts Management</h2>
      
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Financial Reporting
       </h2>
       <p>
       Gain insights into your business's financial health with comprehensive reporting and analytics. Track sales, expenses, 
       profits, and other financial metrics to make informed decisions.</p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Vendor Management
       </h2>
       <p>
       Manage vendor accounts, track purchases, 
       and streamline payment processes to maintain strong vendor relationships and optimize procurement.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Tax Management
       </h2>
       <p>
       Simplify tax compliance with built-in tax management features, including support for different tax rates, exemptions, and reporting requirements.   </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration with Accounting Software
       </h2>
       <p>
       Our software seamlessly integrates with popular accounting software, streamlining the transfer of financial data and eliminating manual data entry errors.   </p>
       </div>
       </div>




       <div className='m-6'>
       <h2 className='font-semibold'>3.	Customer Management </h2>
      
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customer Database
       </h2>
       <p>
       Keep a detailed record of customer information, purchase history, preferences, and contact details to personalize the shopping experience and foster customer loyalty.</p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Loyalty Programs 
       </h2>
       <p>
       Implement customer loyalty programs, reward points, and special promotions
        to encourage repeat business and increase customer retention.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       CRM Integration
       </h2>
       <p>
       Integrate with Customer Relationship Management (CRM) software to align sales and marketing efforts, track customer interactions,
        and enhance customer engagement.  </p>
       </div>
    
       </div>








       <div className='m-6'>
       <h2 className='font-semibold'>4.	Discounts and Promotions Management</h2>
      
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Flexible Discounting
       </h2>
       <p>
       Create and manage a variety of discounts, including percentage-based, fixed amount, bundle discounts, and more.</p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Promotion Scheduling
       </h2>
       <p>
       Plan and schedule promotions in advance, 
       aligning them with seasonal trends, holidays, or other marketing initiatives.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Promotion Performance Analytics
       </h2>
       <p>
       Evaluate the effectiveness of your promotions with built-in analytics, allowing you to fine-tune your marketing strategies based on real-time data.  </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Advanced Retail POS 
       </h2>
       <p>
       By leveraging our advanced Retail POS software, you can streamline inventory operations, gain control over financial management, build lasting customer relationships, and execute effective discounts and promotions strategies to drive business growth and success. </p>
       </div>
       </div>








       <div className='m-6'>
       <h2 className='font-semibold'>5.	Integration with Local Health Authorities  </h2>
      <p>We understand the importance of compliance and collaboration with local health authorities. 
      Our software seamlessly integrates with local health authorities, ensuring that you stay up-to-date 
      with regulations, reporting requirements, and other essential information.
      With our Retail Point of Sales software, you can streamline your business operations, improve 
      efficiency, and provide better service to your customers. Say goodbye to manual processes and
       embrace the power of automation.
      Contact us today to learn more about how our Retail POS software can transform your business. 
      Take your retail operations to the next level with our feature-rich and integrated solution.
      </p>
       </div>
       
       </div>
  )
}

export default ezpos
