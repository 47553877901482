import React from 'react'
import Data from '../components/data'
import prodsimg from '../images/products.jpg'
import indusimg from '../images/industries.png'
import contactimg from '../images/mountain.webp'
import groupimg from '../images/group1.png'
import contactimg2 from '../images/contact2.jpg'
import hero from '../images/tech9.jpg'
import partner from '../images/Logo 1.png'
import partner2 from '../images/Logo 2.png'
import partner3 from '../images/Logo 3.png'
import partner4 from '../images/Logo 4.png'
import partner5 from '../images/Logo 5.png'
import partner6 from '../images/Logo 6.png'
import partner7 from '../images/Logo 7.png'
import banking from '../images/industryicons/banking.svg'
import auto from '../images/industryicons/auto.svg'
import retail from '../images/industryicons/retail.svg'
import healthcare from '../images/industryicons/healthcare.svg'
import education from '../images/industryicons/education.svg'
import realestate from '../images/industryicons/realestate.svg'
import logistics from '../images/industryicons/logistics.svg'
import construction from '../images/industryicons/construction.svg'
import { SiPaloaltosoftware } from "react-icons/si";

import ParticleBackground from '../components/particleBackground'

const Home5 = () => {
  return (
    <div>
{/**-------------------------Hero-------------------------------------------- */}

    <div className="h-screen flex justify-center relative items-center px-12" id="Home6" style={{background:"#26A69A"}}>
    
    



    <div className='grid grid-cols-2 mt-12 relative'>
    
        <div className='text-white'>
        <h1 className='font-bold'>STRONG TECHNOLOGY TEAMS FOR YOUR LONG-LASTING SUCCESS</h1>
      {/**<h1 className='font-bold '>{Data[0].title}</h1>*/}
      {/** 
     <p>{Data[0].desc}</p>*/}
     <p className='text-justify text-sm'>
     
     DPS has over twenty seven years experience of Technology Transformation in Healthcare , 
     Higher education and  Industries sectors with Latest technology and extensive industry experience. 
     A unique outlook on business processes to produce customized solutions for companies across the globe.
     </p>
     <button className='border px-6 py-2 rounded-full text-xs text-white cursor-pointer' style={{background:"#11202A"}}>View Details</button>
     </div> 
     <div className='relative m-auto'>
 
     <img style={{width:"50%"}}/>
     <div className='absolute top-1/2 left-1/2 transform -translate-x-3/4 -translate-y-3/4" bg-white blur-2xl' style={{width:"50vw", height:"25px"}}></div>
     </div>
     </div>
     
 </div>
 {/**-------------------------Experience-------------------------------------------- */}

 <div className="h-auto py-12 px-12 bg-blue-400">
 <div className='grid-cols-2 grid mb-12'>
 <h2 className='  font-semibold text-black'>BENEFIT FROM DECADES OF <br/> PROVEN ENGINEERING <br/>EXPERTISE</h2>
 <p className='text-justify text-black text-xs'>{Data[15].desc}</p>
 </div>
 <div className='grid grid-cols-10 gap-x-4'>
 <div className='col-span-2'>
 <h7 className='text-8xl font-bold flex justify-end'>27+</h7>
 </div>
 <div className='col-span-3'>
 <h3 className='text-sm font-semibold border-b-2 py-2'>
 Years of Experience
 </h3>
 <p className='text-justify text-xs'>
 At DPS Software, we have an expert team of vetted developers 
 with ample experience in automating business processes and building apps of different sizes and complexity.
 </p>
 </div>
 <div className='col-span-2 mt-12 flex justify-end'>
 <h7 className='mt-12 text-8xl font-bold'>
 280</h7>
 </div>
 <div className='mt-12 col-span-3'>
 <h3 className='text-sm mt-12 border-b-2 font-semibold py-2'>Pharmacies</h3>
 <p className='text-justify text-xs'>We believe in reliable partnership. Our company builds open, transparent, 
 and trusting relationships with our clients and is always ready to assist you in your tech endeavors.</p>
 </div>
 </div>
 <div className='grid grid-cols-10 gap-x-6'>
 <div className='col-span-2'>
 <h7 className='text-8xl font-bold flex justify-end'>25+</h7>
 </div>
 <div className='col-span-3'>
 <h3 className='text-sm border-b-2 font-semibold py-2'>
 Years of Experience
 </h3>
 <p className='text-justify text-xs'>
 At DPS Software, we have an expert team of vetted developers 
 with ample experience in automating business processes and building apps of different sizes and complexity.
 </p>
 </div>
 <div className='col-span-2 mt-12'>
 <h7 className='mt-12 text-8xl font-bold flex justify-end'>
 290</h7>
 </div>
 <div className='mt-12 col-span-3'>
 <h3 className='text-sm mt-12 border-b-2 font-semibold py-2'>Pharmacies</h3>
 <p className='text-justify text-xs'>We believe in reliable partnership. Our company builds open, transparent, 
 and trusting relationships with our clients and is always ready to assist you in your tech endeavors.</p>
 </div>
 </div>
 
</div>
{/**-------------------------Industriesdummy-------------------------------------------- */}

<div className="h-screen relative justify-center items-center pt-12" id="industries3">
 
<h2 className='text-center  text-black text-sm font-semibold'>{Data[5].title}</h2>
<p className='text-center text-black'>{Data[5].desc}

</p>



<div className='grid-cols-4 grid w-5/6 mx-auto my-12'>
<div className='m-auto font-semibold'>
    <img src={banking} />
    <h4 className='text-black text-center'>Banking</h4>
    </div>
    <div className='m-auto'>
    <img src={education} />
    <h4 className='text-black text-center font-semibold'>Education</h4>
    </div>
    <div className='m-auto'>
    <img src={healthcare} />
    <h4 className='text-black text-center font-semibold'>Healthcare</h4>
    </div>
    <div className='m-auto'>
    <img src={auto} />
    <h4 className='text-black text-center font-semibold'>Auto</h4>
    </div>

</div>
<div className='grid-cols-4 grid w-5/6 ms-auto'>
<div className='m-auto'>
    <img src={logistics} />
    <h4 className='text-black text-center font-semibold'>Logistics</h4>
    </div>
    <div className='m-auto'>
    <img src={retail} />
    <h4 className='text-black text-center font-semibold'>Retail</h4>
    </div>
    <div className='m-auto'>
    <img src={healthcare} />
    <h4 className='text-black text-center font-semibold'>Healthcare</h4>
    </div>
    <div className='m-auto'>
    <img src={construction} />
    <h4 className='text-black text-center font-semibold'>Construction</h4>
    </div>

</div>

</div>

{/**-------------------------Industries-------------------------------------------- 

<div className="h-screen relative justify-center items-center pt-12" id="industries2" >

<h2 className='text-center '>{Data[5].title}</h2>
<p className='text-center text-black'>{Data[5].desc}</p>


<img className='absolute indusimg' src={indusimg}/>
<div className='grid grid-cols-4 px-12 gap-x-12 indus-right'>
<div className='col-span-3'>
</div>
<div className='bg-violet-900 bg-opacity-70 flex flex-col items-center justify-center text-white px-4 py-12' style={{height:"80vh"}} >
<h2 className='font-bold '>{Data[9].title}</h2>
<h2>{Data[9].desc}</h2>
</div>
</div>
</div>*/}
{/**-------------------------Servicesdummy-------------------------------------------- */}

<div className="h-auto justify-center items-center px-12 py-12" id="services3">

<div className='grid grid-cols-2'><h2 className=' text-black font-semibold'>FROM CONCEPT TO COMPLETION:<br/> OUR FULL-STACK <br/>EXPERTISE</h2>
<p className='text-center text-black text-justify'>{Data[1].desc}

At DPS Software, we have an expert team of vetted developers with
 ample experience in<span className='font-bold service-text'> automating business</span> processes and building apps of different sizes and complexity.
</p></div>
<div className='grid grid-cols-3 py-12 px-12 gap-x-12 text-white'>
{Data.slice(2,5).map((services)=>(
 <div className='shadow-2xl relative rounded-3xl py-12 px-6 bg-black'>
 <SiPaloaltosoftware className=' m-auto' />
<h2 className='text-center text-sm py-6 font-semibold'>{services.title}</h2>
<p className='text-justify'>{services.desc}</p>

</div>
))}
</div>
</div>




{/**-------------------------partners-------------------------------------------- */}

<div className="h-auto relative justify-center bg-black shadow-2xl items-center px-12 pt-12" id="partners3" >


<h2 className='text-center text-white  font-semibold'>OUR VALUABLE <br/>
PARTNERS</h2>
<p className='text-center text-white'>We value our partners and we believe in
cooperation than competition</p>
<div className="logos">
<div className='logos-slides py-12'>
<img src={partner}  />
<img src={partner2} />
<img src={partner3} />
<img src={partner4} />
<img src={partner5} />
<img src={partner6} />
<img src={partner7} />
</div>
</div>

</div>


{/**-------------------------Productsdummy-x-------------------------------------------- */}
<div class="bg-white dark:bg-gray-800 h-auto h-full py-6 sm:py-8 lg:py-12 my-12">
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
            <div class="flex items-center gap-12">
                <h2 class="text-sm font-bold text-gray-800 lg: dark:text-white">Gallery</h2>

                <p class="hidden max-w-screen-sm text-gray-500 dark:text-gray-300 md:block">
                    This is a section of some simple filler text,
                    also known as placeholder text. It shares some characteristics of a real written text.
                </p>
            </div>

            <a href="#"
                class="inline-block rounded-lg border bg-white dark:bg-gray-700 dark:border-none px-4 py-2 text-center text-sm font-semibold text-gray-500 dark:text-gray-200 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base">
                More
            </a>
        </div>

        <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
        
            <a href="#"
                class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                <img src="https://images.unsplash.com/photo-1593508512255-86ab42a8e620?auto=format&q=75&fit=crop&w=600" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">VR</span>
            </a>
          
            <a href="#"
                class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                <img src="https://images.unsplash.com/photo-1542759564-7ccbb6ac450a?auto=format&q=75&fit=crop&w=1000" loading="lazy" alt="Photo by Magicle" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Tech</span>
            </a>
         
            <a href="#"
                class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                <img src="https://images.unsplash.com/photo-1610465299996-30f240ac2b1c?auto=format&q=75&fit=crop&w=1000" loading="lazy" alt="Photo by Martin Sanchez" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Dev</span>
            </a>
        

            <a href="#"
                class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                <img src="https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&q=75&fit=crop&w=600" loading="lazy" alt="Photo by Lorenzo Herrera" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

                <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Retro</span>
            </a>
        
        </div>
    </div>
</div>


{/**-------------------------groupdummy-------------------------------------------- */}

<div id="group2" className='relative px-12 pt-12'>
<div className='h-screen'>

<div className=''>
<h2 className='font-semibold text-center text-black '>
THE PLACES, WHERE <br/> WE OPERATE
</h2>
<p className=' text-center'>DPS is a giant conglomerate of DPS Canada, IT has its offices established
in five different countries  </p>
</div>
<div className='grid grid-cols-2 my-6'>
<img src={groupimg} style={{width:"80%"}}/>
</div>
</div>
</div>

{/**-------------------------contactdummy-------------------------------------------- */}

<div>
<div id="contact3" className='h-screen relative'>

<div className='w-2/3 text-white m-auto contact-sub3 bg-black -translate-y-8 h-full py-12 rounded-3xl'>
<div className='text-center'>
{/**
 */}
<h2 className='font-bold '>
Contact Us
</h2>

{Data[14].desc.map((cont)=>(<p>{cont}</p>))}

<form className='flex flex-col'>
      <label className=''>Name: <br/>
        <input type="text" className='px-12 rounded-full' />
      </label>
      <br/>
      <label className=''>Email:<br/>
        <input type="text mt-12" className='px-12 rounded-full' />
      </label>
      <br/>
      <label>Subject:<br/>
        <input type="text" className='px-12 rounded-full' />
      </label><br/>
      <label>Message: <br/>
        <textarea type="text" className='px-12 rounded-full' />
      </label>
      <br/>
      <button className='border px-6 rounded-full w-1/3 m-auto'>SEND MESSAGE</button>
    </form>
</div>
<div>
{/**
<img src={contactimg2} className='contactimg2' /> */}
</div>
</div>
<div>


</div>

</div>

</div>



    </div>
  )
}





export default Home5                                                                                                                                                                                            
