import React from 'react'
import ecommerceimg from '../images2/ecommerce.png'
import offeringsbg from '../images2/offering-bg.png'
const ecommerce = () => {
  return (
    <div className='text-slate-700'>
    <div className='product-inside grid flex flex-col items-center h-80 grid-cols-1 lg:grid-cols-12 py-6 px-6' style={{backgroundImage: `url(${ecommerceimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='col-span-5'>
      <h2 className='text-2xl font-bold uppercase'>Empower Your <pr></pr> Business with Our Versatile <pr></pr> <span className='text-red-500'>e-Commerce Solution</span></h2>
      <p>Whether you're a small-scale enterprise looking to establish an online store presence at our vibrant marketplace or a large business in need of an independent eCommerce platform, our
       comprehensive eCommerce solution is tailored to elevate your digital presence and drive business growth.</p>
       </div>

       <div className='col-span-7'>
      
       </div>
       </div>
       
       <div className='mx-6 my-6'>
       <h2 className='uppercase text-2xl font-semibold text-slate-700'>
       For small-SCALE <span className='text-red-500'> businesses</span>
       </h2>
       <p>Step into the digital marketplace with ease and confidence. Our eCommerce product offers small-scale businesses the opportunity to set up and showcase their products through our dynamic marketplace. Benefit from a ready-made platform to reach a broader audience, boost sales, and
        establish a compelling online storefront without the complexity of developing an independent eCommerce site
       </p>
       <h2 className='font-semibold text-2xl uppercase my-6 text-slate-700'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 md:grid-cols-3  gap-x-3 mb-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Plug-and-Play Online Store
       </h2>
       <p>
       Quickly set up an online
        store within our marketplace, leveraging user-friendly tools to feature products, process transactions </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Access to a Wider Audience
       </h2>
       <p>
       Tap into a diverse customer base,
        expand your reach, and increase brand visibility through our established marketplace ecosystem </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Streamlined Management
       </h2>
       <p>
       Simplify product listings, 
       inventory management, and order fulfilment with intuitive tools designed for small-scale operations   </p>
       </div>
       </div>

        
       <div className='mx-6 my-6'>
       <h2 className='uppercase text-2xl font-semibold text-slate-700'>
       For large-SCALE <span className='text-red-500'> businesses</span>
       </h2>
       <p>Take full control of your eCommerce journey with a robust and customizable platform designed specifically for large-scale enterprises. Our eCommerce solution empowers large businesses to build and manage their
        independent online storefront, tailored to their unique branding, scalability, and operational requirements.
       </p>
       <h2 className='font-semibold text-2xl text-slate-700 uppercase my-6'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-x-3 mb-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Customisable Branding
       </h2>
       <p>
       Tailor your eCommerce platform to reflect your brand
        identity, offering a seamless and immersive customer experience aligned with your brand values </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Scalable Infrastructure
       </h2>
       <p>
       Accommodate high transaction volumes, extensive product catalogs, and diverse business operations with a robust and scalable eCommerce architecture </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Advanced Analytics & Insights
       </h2>
       <p>
       Harness the power of data-driven decision-making with comprehensive analytics, customer insights, and performance tracking tools.   </p>
       </div>
       </div>

       </div>
  )
}

export default ecommerce
