import React from 'react'
import fintecimg from '../images2/fintech.jpg'
import offeringsbg from '../images2/offering-bg.png'
const fintec = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid h-96 product-inside grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${fintecimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className=' flex flex-col justify-center'>
      <h2 className='text-2xl font-bold uppercase'>Transform Your   Financial Management with Our Dynamic  <span className='text-red-500'>fintech Solution</span></h2>
      <p className='xxs:text-sm xs:text-base'>Empower your business with our cutting-edge FinTech product, designed to cater to the diverse financial management needs of both small and large-scale enterprises. Whether you're a growing startup or an established corporation, our comprehensive FinTech solution provides 
      the tools and insights necessary to streamline financial operations and drive sustainable growth.</p>
       </div>

       <div>
      
       </div>
       </div>
       
       <div className='mx-6 my-6'>
       <h2 className='uppercase text-2xl font-semibold text-slate-700'>
       For small-SCALE <span className='text-red-500'> businesses</span>
       </h2>
       <p>Elevate your financial management practices with ease and precision. Our FinTech product offers small-scale businesses a robust suite of financial tools and resources tailored to their specific needs. From simplified invoicing to cash flow management and financial reporting, our solution empowers small enterprises to efficiently manage their finances and make informed decisions.
       </p>
       <h2 className='font-semibold text-2xl text-slate-700 uppercase my-6'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 mb-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Simplified Invoicing and Payments
       </h2>
       <p>
       Expedite invoicing processes, facilitate secure payments, 
       and enhance cash flow management through intuitive and user-friendly financial tools. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Financial Insights and Reporting
       </h2>
       <p>
       Gain valuable insights into business finances, 
       track key performance indicators, and generate comprehensive reports to support strategic decision-making.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Cost-Effective Solutions
       </h2>
       <p>
       Access affordable and scalable financial management
        tools that align with the budget and operational scope of small-scale enterprises.   </p>
       </div>
       </div>

        
       <div className='mx-6 my-6'>
       <h2 className='uppercase text-2xl font-semibold text-slate-700'>
       For large-SCALE <span className='text-red-500'> businesses</span>
       </h2>
       <p>Harness the power of advanced financial technology to optimize complex financial operations at scale. Our FinTech solution for large-scale businesses offers a comprehensive suite of financial management capabilities,
        enabling organizations to drive efficiency, transparency, and strategic financial planning.  </p>
       <h2 className='font-semibold text-2xl uppercase my-6 text-slate-700'>KEY <span className='text-red-500'> FEATURE</span></h2>
        </div>
       
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2 lg:grid-cols-3  gap-x-3 mb-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Enterprise-Grade Financial Tools
       </h2>
       <p>
       Access advanced financial modules, enterprise resource planning (ERP) integration, 
       and customizable solutions to address the sophisticated needs of large-scale operations. </p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Risk Management and Compliance
       </h2>
       <p>
       Mitigate financial risks, ensure regulatory compliance, and enhance governance practices 
       through robust risk management features embedded within our FinTech solution. </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Scalable Infrastructure
       </h2>
       <p>
       Seamlessly expand and adapt financial processes, reporting structures, and operational workflows to accommodate the growth and complexity of large-scale enterprises.  </p>
       </div>
       </div>
       <div className='mx-6 my-6'>
       <h2 className='uppercase text-2xl font-semibold'>
       Optimize Your  <span className='text-red-500'> Financial Landscape</span>
       </h2>
       <p>Experience the transformative power of FinTech in redefining your financial management practices. Our FinTech solution caters to the unique needs of both small and large-scale businesses, offering a pathway to enhanced financial control, operational efficiency, and sustainable business growth.
       Drive financial excellence and strategic decision-making with our dynamic FinTech solution.
       
       </p>
       
        </div>
       </div>
  )
}

export default fintec
