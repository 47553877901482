import React from 'react'
import groupimg from '../images2/group.jpg'
const group = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid grid-cols-1 lg:grid-cols-2 my-6 mx-6'>
    <div className='flex flex-col justify-center'>
      <h2 className='text-xl lg:text-3xl font-bold uppercase'>Our Pharmacy Solution Offering for <span className='text-red-500'>Group of Pharmacies </span> </h2>
      <p className='sm:text-sm md:text-base'>We understand the unique requirements of medium pharmacy businesses with multiple interconnected pharmacies. We are dedicated to providing comprehensive solutions that cater to your specific needs. Our products and
       features are designed to streamline your operations and enhance your business efficiency.</p>
       </div>
       <div>
       <img className='' src={groupimg}/>
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='font-bold text-xl lg:text-3xl uppercase'>Products and<span className='text-red-500'> Features</span></h2>
       <p className='text-sm md:text-base'>Our RSD (KSA) and Tatmeen (UAE) compliant EzPOS & EzRX, eInventory & AMS solution is a powerful tool that empowers medium pharmacy businesses with a range of
        essential features to optimize their operations. The following key components are included:</p>
       </div>
       </div>
       <div className='grid xxs:grid-cols-1 xs:grid-cols-2  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
      	EzPOS - Point of Sale of Multiple Pharmacies
       </h2>
       <p className='text-sm md:text-base'>
       Our POS system is tailored to meet the specific needs of medium pharmacy businesses with multiple interconnected pharmacies. It provides a seamless Sales process and efficient transaction management across all your pharmacies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Patient Management
       </h2>
       <p className='text-sm md:text-base'>
       Streamline patient records, prescription history, and communication with our integrated patient management system. This feature enhances customer service and care across all your interconnected pharmacies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       eInventory - Inventory Management of All Pharmacies
       </h2>
       <p className='text-sm md:text-base'>
       Efficiently manage the inventory of all your interconnected pharmacies. This feature ensures optimal stock levels, streamlined supply chain management, and real-time inventory tracking.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       EzRx - Claim & Remittance Management of All Pharmacies
       </h2>
       <p className='text-sm md:text-base'>
       Simplify the process of managing health insurance claims and remittances across all your interconnected pharmacies. This feature ensures hassle-free interactions with leading health insurance companies.
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       AMS - Accounts Management of All Pharmacies
       </h2>
       <p className='text-sm md:text-base'>
       Our solution includes robust accounts management capabilities, allowing you to handle financial transactions, reporting, and reconciliation across all your interconnected pharmacies. 
       
       </p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Integration with RSD (KSA) and Tatmeen (UAE)
       </h2>
       <p className='text-sm md:text-base'>
       Seamlessly integrate with RSD (KSA) and Tatmeen (UAE) to ensure compliance and efficient data exchange. This integration enhances operational efficiency and regulatory compliance.
       </p>
       </div>
       </div>
       <div className=' offering-bg'>
       <div className='text-red-500 font-bold text-center'>
       <h2 className='pt-6'>BENEFITS FOR GROUP OF PHARMACIES</h2>
       </div>
       <div className='grid xxs: xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Comprehensive 
Solution
       </h2>
       <p className='text-sm md:text-base'>
       Our EzPOS & EzRX, eInventory & AMS solution offers a comprehensive set of features tailored to the unique needs of medium pharmacy businesses with multiple interconnected pharmacies. It covers essential aspects of operations, patient care, and financial management.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Efficiency 
and Streamlining
       </h2>
       <p className='text-sm md:text-base'>
       By integrating point of sale, patient management, inventory management, and accounts 
       management, our solution enables pharmacies to streamline their operations and improve overall efficiency across all your interconnected pharmacies.
       </p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Enhanced Customer Service
       </h2>
       <p className='text-sm md:text-base'>
       Improved patient management capabilities allow for better customer service and care, ensuring a positive experience for your pharmacy's clientele across all your interconnected pharmacies.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Hassle-free Claim Management
       </h2>
       <p className='text-sm md:text-base'>
       The inclusion of claim and remittance management simplifies interactions with health insurance companies, reducing administrative burden and ensuring efficient payer management across all your interconnected pharmacies.</p>
       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold'>
       Regulatory Compliance
       </h2>
       <p className='text-sm md:text-base'>
       Integrated with RSD (KSA) and Tatmeen, Malaffi and ADHICS Compliance in UAE, our solution ensures compliance with regulations and facilitates seamless data exchange for enhanced operational efficiency and regulatory compliance across all your interconnected pharmacies.</p>
       </div>
       </div>
       <p className='m-6 text-sm md:text-base'>We are committed to empowering medium pharmacy businesses with the tools and capabilities they need to thrive in a competitive healthcare landscape. Our EzPOS & EzRX, eInventory & AMS solution is designed to enhance your operations and provide a seamless experience for both your pharmacies and your patients.</p>
       </div>
       </div>
  )
}

export default group
