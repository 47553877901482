import React from 'react'
import Data from '../components/data'
import prodsimg from '../images/products.jpg'
import indusimg from '../images/industries.png'
import contactimg from '../images/mountain.webp'
import groupimg from '../images/group1.png'
import groupimg2 from '../images/map3.png'
import contactimg2 from '../images/contact2.jpg'
import hero from '../images/tech9.jpg'
import partner from '../images/Logo 1.png'
import partner2 from '../images/Logo 2.png'
import partner3 from '../images/Logo 3.png'
import partner4 from '../images/Logo 4.png'
import partner5 from '../images/Logo 5.png'
import partner6 from '../images/Logo 6.png'
import partner7 from '../images/Logo 7.png'
import banking from '../images/industryicons/banking.svg'
import auto from '../images/industryicons/auto.svg'
import retail from '../images/industryicons/retail.svg'
import healthcare from '../images/industryicons/healthcare.svg'
import education from '../images/industryicons/education.svg'
import realestate from '../images/industryicons/realestate.svg'
import logistics from '../images/industryicons/logistics.svg'
import construction from '../images/industryicons/construction.svg'
import { SiPaloaltosoftware } from "react-icons/si";
import heroimg from '../images/pattern3.jpg'
import ParticleBackground from '../components/particleBackground'
import product1 from '../images/eInventory.jpg';
import product2 from '../images/ezpos.jpg'
import product3 from '../images/education.png'
const Home2 = () => {
  return (
    <div>
{/**-------------------------Hero-------------------------------------------- */}

    <div className="h-screen flex justify-center relative items-center px-12" id="home2">
    <ParticleBackground />
    



    <div className='grid grid-cols-2 mt-12 relative'>
    
        <div className='text-white'>
        <h1 className='font-bold text-2xl'>STRONG <br/> TECHNOLOGY TEAMS <br/> FOR YOUR LONG-LASTING SUCCESS</h1>
      {/**<h1 className='font-bold text-3xl'>{Data[0].title}</h1>*/}
      {/** 
     <p>{Data[0].desc}</p>*/}
     <p className='text-justify'>
     
     DPS has over twenty seven years experience of Technology Transformation in Healthcare , 
     Higher education and  Industries sectors with Latest technology and extensive industry experience. 
     A unique outlook on business processes to produce customized solutions for companies across the globe.
     </p>
     <button className='border px-6 py-2 rounded-full cursor-pointer bg-green-400'>View Details</button>
     </div> 
     <div className='relative m-auto'>
 
     <img src={hero} className='mix-blend-multiply heroimg' style={{height:"350px"}}/>
     <div className='absolute top-1/2 left-1/2 transform -translate-x-3/4 -translate-y-3/4" bg-white blur-2xl' style={{width:"50vw", height:"25px"}}></div>
     </div>
     </div>
     
 </div>
 {/**-------------------------Experience-------------------------------------------- */}

 <div className="h-auto py-12 mx-12" id="services">
 <div className='grid-cols-2 grid mb-12'>
 <h2 className=' text-3xl font-semibold text-black'>BENEFIT FROM DECADES OF PROVEN   ENGINEERING<br/> EXPERTISE</h2>
 <p className='text-justify text-black'>{Data[15].desc}</p>
 </div>
 <div className='grid grid-cols-10 gap-x-4'>
 <div className='col-span-2'>
 <h6 className='text-8xl font-bold flex justify-end' style={{color:"#059669"}}>27+</h6>
 </div>
 <div className='col-span-3'>
 <h3 className='text-2xl font-semibold border-b-2 py-2'>
 Years of Experience
 </h3>
 <p className='text-justify'>
 At DPS Software, we have an expert team of vetted developers 
 with ample experience in automating business processes and building apps of different sizes and complexity.
 </p>
 </div>
 <div className='col-span-2 mt-12 flex justify-end'>
 <h6 className='mt-12 text-8xl font-bold' style={{color:"#059669"}}>
 280</h6>
 </div>
 <div className='mt-12 col-span-3'>
 <h3 className='text-2xl mt-12 border-b-2 font-semibold py-2'>Pharmacies</h3>
 <p className='text-justify'>We believe in reliable partnership. Our company builds open, transparent, 
 and trusting relationships with our clients and is always ready to assist you in your tech endeavors.</p>
 </div>
 </div>
 <div className='grid grid-cols-10 gap-x-6'>
 <div className='col-span-2'>
 <h6 className='text-8xl font-bold  flex justify-end' style={{color:"#059669"}}>25+</h6>
 </div>
 <div className='col-span-3'>
 <h3 className='text-2xl border-b-2 font-semibold py-2'>
 Years of Experience
 </h3>
 <p className='text-justify'>
 At DPS Software, we have an expert team of vetted developers 
 with ample experience in automating business processes and building apps of different sizes and complexity.
 </p>
 </div>
 <div className='col-span-2 mt-12'>
 <h6 className='mt-12 text-8xl font-bold flex justify-end' style={{color:"#059669"}}>
 290</h6>
 </div>
 <div className='mt-12 col-span-3'>
 <h3 className='text-2xl mt-12 border-b-2 font-semibold py-2'>Pharmacies</h3>
 <p className='text-justify'>We believe in reliable partnership. Our company builds open, transparent, 
 and trusting relationships with our clients and is always ready to assist you in your tech endeavors.
 
 </p>
 </div>
 </div>
 
</div>
{/**-------------------------Industriesdummy-------------------------------------------- */}

<div className="h-screen relative justify-center items-center pt-12" id="industries2">
 
<h2 className='text-center text-3xl text-black font-semibold'>{Data[5].title}</h2>
<p className='text-center text-black'>{Data[5].desc}

</p>



<div className='grid-cols-4 grid w-5/6 mx-auto my-12'>
<div className='m-auto font-semibold'>
    <img src={banking} />
    <h4 className='text-black text-center'>Banking</h4>
    </div>
    <div className='m-auto'>
    <img src={education} />
    <h4 className='text-black text-center font-semibold'>Education</h4>
    </div>
    <div className='m-auto'>
    <img src={healthcare} />
    <h4 className='text-black text-center font-semibold'>Healthcare</h4>
    </div>
    <div className='m-auto'>
    <img src={auto} />
    <h4 className='text-black text-center font-semibold'>Auto</h4>
    </div>

</div>
<div className='grid-cols-4 grid w-5/6 ms-auto'>
<div className='m-auto'>
    <img src={logistics} />
    <h4 className='text-black text-center font-semibold'>Logistics</h4>
    </div>
    <div className='m-auto'>
    <img src={retail} />
    <h4 className='text-black text-center font-semibold'>Retail</h4>
    </div>
    <div className='m-auto'>
    <img src={healthcare} />
    <h4 className='text-black text-center font-semibold'>Healthcare</h4>
    </div>
    <div className='m-auto'>
    <img src={construction} />
    <h4 className='text-black text-center font-semibold'>Construction</h4>
    </div>

</div>

</div>

{/**-------------------------Industries-------------------------------------------- 

<div className="h-screen relative justify-center items-center pt-12" id="industries2" >

<h2 className='text-center text-3xl'>{Data[5].title}</h2>
<p className='text-center text-black'>{Data[5].desc}</p>


<img className='absolute indusimg' src={indusimg}/>
<div className='grid grid-cols-4 px-12 gap-x-12 indus-right'>
<div className='col-span-3'>
</div>
<div className='bg-violet-900 bg-opacity-70 flex flex-col items-center justify-center text-white px-4 py-12' style={{height:"80vh"}} >
<h2 className='font-bold text-3xl'>{Data[9].title}</h2>
<h2>{Data[9].desc}</h2>
</div>
</div>
</div>*/}
{/**-------------------------Servicesdummy-------------------------------------------- */}

<div className="h-auto justify-center items-center px-12 py-12" id="services2">

<div className='grid grid-cols-2'><h2 className='text-3xl text-black font-semibold'>FROM CONCEPT TO COMPLETION:<br/> OUR FULL-STACK <br/>EXPERTISE</h2>
<p className='text-center text-black text-justify'>{Data[1].desc}

At DPS Software, we have an expert team of vetted developers with
 ample experience in<span className='font-bold service-text'> automating business</span> processes and building apps of different sizes and complexity.
</p></div>
<div className='grid grid-cols-3 py-12 px-12 gap-x-12 text-white'>
{Data.slice(2,5).map((services)=>(
 <div className='shadow-2xl relative rounded-3xl py-12 px-6 bg-blue-200 services-sub2'>
 <SiPaloaltosoftware className='text-3xl m-auto' />
<h2 className='text-center text-2xl py-6 font-semibold'>{services.title}</h2>
<p className='text-justify'>{services.desc}</p>

</div>
))}
</div>
</div>


{/**-------------------------Services-------------------------------------------- 

    <div className="h-auto justify-center items-center py-12" id="services"  style={{background:"#F7F8FC"}}>
   <h2 className='text-center text-3xl'>{Data[1].title}</h2>
   <p className='text-center text-black'>{Data[1].desc}</p>
   <div className='grid grid-cols-3 py-12 px-12 gap-x-12'>
   {Data.slice(2,5).map((services)=>(
    <div className='text-center bg-white shadow-lg border py-12 px-6'>
 <h2 className='font-bold text-2xl py-6' style={{color:"#059669"}}>{services.title}</h2>
 <p className='text-black'>{services.desc}</p>
 
 </div>
 ))}
   </div>
 </div>*/}
 
{/**-------------------------dummy-------------------------------------------- */}





{/**-------------------------partners-------------------------------------------- */}

<div className="h-auto relative justify-center shadow-2xl items-center px-12 pt-12" id="partners2" style={{ background: "radial-gradient(#43cea2, #185a9d)"}} >


<h2 className='text-center text-white text-3xl font-semibold'>OUR VALUABLE <br/>
PARTNERS</h2>
<p className='text-center text-white'>We value our partners and we believe in
cooperation than competition</p>
<div className="logos">
<div className='logos-slides py-12'>
<img src={partner}  />
<img src={partner2} />
<img src={partner3} />
<img src={partner4} />
<img src={partner5} />
<img src={partner6} />
<img src={partner7} />
</div>
</div>
{/**
<img className='absolute indusimg' src={indusimg}/> */}

</div>


{/**-------------------------Productsdummy-x-------------------------------------------- */}
<div className='grid grid-cols-3 px-12 my-12 gap-x-4 relative'>
<div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={product1} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">Healthcare Products</div>
    <li class="text-gray-700 text-base">
     EZPOS
    </li>
    <li class="text-gray-700 text-base">
     EZRX
    </li>
    <li class="text-gray-700 text-base">
     EInventory
    </li>
    <li class="text-gray-700 text-base">
     AMS
    </li>
    <li class="text-gray-700 text-base">
     Ecommerce
    </li>
    <li class="text-gray-700 text-base">
     Dashboard
    </li>
  </div>
  <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
  </div>
</div>
<div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={product2} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">ERP</div>
    <li class="text-gray-700 text-base">
    AMS
        </li>
        <li class="text-gray-700 text-base">
    ERP
        </li>
  </div>
  <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
  </div>
</div>
<div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={product3} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">Educational Products</div>
    <li class="text-gray-700 text-base">
    CampusTek
        </li>
        <li class="text-gray-700 text-base">
    ERP
        </li>
  </div>
  <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
  </div>
</div>
</div>


{/**-------------------------Productsdummy-------------------------------------------- 
<div className='my-12 px-12 py-12'>
<h2 className='text-center text-black text-3xl font-semibold'>PRODUCTS THAT OUR <br/>CUSTOMERS USE</h2>

<h2 className='text-center text-3xl'>{Data[10].title}</h2> 
<p className='text-center text-black my-4'>{Data[10].desc}</p>
<div className='grid grid-cols-3 gap-4'>
{Data.slice(11,14).map((products)=>(<div class="overflow-hidden relative bg-white rounded shadow-md text-slate-500 shadow-slate-200">

<figure>
  <img src="https://picsum.photos/id/101/800/600" alt="card image" class="aspect-video w-full" />
</figure>

<div class="p-6">
  <header class="mb-4">
    <h3 class="text-xl font-medium text-black">The easy way to go</h3>
    <p class="text-sm text-black"> By George, jun 3 2023</p>
  </header>
  <p className='relative text-black text-justify'>
    Experience the simple pleasures of a world with no cars, and only gentle walks through palm tree forests, and fallen coconuts. An island that’s home to extraordinary cliffs, swelling oceans, and mammoth manta rays.
 
    <div className='absolute top-1/2 left-1/2 transform -translate-x-3/4 -translate-y-3/4" bg-teal-500 blur-2xl' style={{width:"50vw", height:"50px"}}></div>
    </p>
</div>
</div>))}</div>
</div>*/}

{/**-------------------------Products-------------------------------------------- 

<div className="h-auto justify-center items-center relative my-12"  id="products">

<h2 className='text-center text-3xl'>{Data[10].title}</h2>
<p className='text-center text-black'>{Data[10].desc}</p>

<div className='grid grid-cols-3 py-12 px-12 gap-x-12'>
{Data.slice(11,14).map((products)=>(
 <div className='text-center border py-12 px-6'>
<h2 className='font-bold text-2xl py-6' style={{color:"#059669"}}>{products.title}</h2>
<p className=' text-black'>{products.desc}</p>

</div>
))}
</div>
</div>

*/}

{/**-------------------------groupdummy-------------------------------------------- */}

<div id="group2" className='relative px-12 pt-12'>
<div className='h-screen'>

<div className=''>
<h2 className='font-semibold text-center text-black text-3xl'>
THE PLACES, WHERE <br/> WE OPERATE
</h2>
<p className=' text-center'>DPS is a giant conglomerate of DPS Canada, DPS has its offices established
in five different countries  </p>
</div>
<div className='grid grid-cols-2 my-6'>
<img src={groupimg} style={{width:"80%"}}/>
<div className='text-black'>
<h2 className='text-center font-bold'>HSA Software Alliance</h2>
<img src={groupimg2} style={{width:"80%"}}/>
</div>
</div>
</div>
</div>

{/**-------------------------contactdummy-------------------------------------------- */}

<div>
<div id="contact2" className='h-auto relative'>

<div className='w-2/3 text-white m-auto contact-sub2 my-12 py-12 h-full rounded-3xl' style={{background:"#40C5A2"}}>
<div className='text-center'>
{/**
 */}
<h2 className='font-bold text-3xl'>
Contact Us
</h2>

{Data[14].desc.map((cont)=>(<p>{cont}</p>))}

<form className='flex flex-col'>
      <label className=''>Name: <br/>
        <input type="text" className='px-12 rounded-full' />
      </label>
      <br/>
      <label className=''>Email:<br/>
        <input type="text mt-12" className='px-12 rounded-full' />
      </label>
      <br/>
      <label>Subject:<br/>
        <input type="text" className='px-12 rounded-full' />
      </label><br/>
      <label>Message: <br/>
        <textarea type="text" className='px-12 rounded-full' />
      </label>
      <br/>
      <button className='border px-6 rounded-full w-1/3 m-auto'>SEND MESSAGE</button>
    </form>
</div>
<div>
{/**
<img src={contactimg2} className='contactimg2' /> */}
</div>
</div>
<div>


</div>

</div>

</div>

{/**-------------------------contact-------------------------------------------- 

<div>
<div>
<div className='grid grid-cols-2 relative'>
<div className='text-center'>
<img src={contactimg} className='absolute contactimg'/>
<h2 className='text-red-500 font-bold text-3xl'>
Contact Us
</h2>

{Data[14].desc.map((cont)=>(<p>{cont}</p>))}

<form className=''>
      <label>Name: <br/>
        <input type="text" />
      </label>
      <br/>
      <label>Name:<br/>
        <input type="text" />
      </label>
      <br/>
      <label>Name:<br/>
        <input type="text" />
      </label><br/>
      <label>Name: <br/>
        <input type="text" />
      </label>
      <br/>
      <button className='border'>SEND MESSAGE</button>
    </form>
</div>
<div>
<img src={contactimg2} className='contactimg2' />
</div>
</div>
<div>


</div>

</div>

</div>*/}

    </div>
  )
}





export default Home2
