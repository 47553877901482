import React from 'react'
import ezrximg from '../images2/EzRx.jpg'
import offeringsbg from '../images2/offering-bg.png'
const ezrx = () => {
  return (
    <div className='text-slate-700'>
    <div className='grid h-80 product-inside grid-cols-1 lg:grid-cols-2 py-6 px-6' style={{backgroundImage: `url(${ezrximg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'}}>
    <div className='text-slate-700 flex flex-col justify-center'>
      <h2 className='text-2xl font-bold uppercase'>Introducing our  <span className='text-red-500'>EzRx</span>, to manage<p className='hidden lg:block'></p> health insurance claims. </h2>
      <p>That's why we've developed a cutting-edge EzRx, Health Insurance Claim Management Software, seamlessly integrated with leading health insurance companies, to streamline your operations and ensure hassle-free management.</p>
       </div>

       <div>
      
       </div>
       </div>
       <div>
       <div className='mx-6'>
       <h2 className='text-slate-700 text-2xl mt-6 font-bold'>KEY <span className='text-red-500'> FEATURES</span></h2>
        </div>
       </div>
       <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-x-3 my-6 mx-6'>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Efficient Payer Management
       </h2>
       <p>
       Our software provides robust tools for efficient payer management, allowing you to effortlessly navigate through various insurance providers' requirements and protocols. With intuitive interfaces and automated processes, you can easily verify patient eligibility, submit claims, and track reimbursements with precision and speed. Say goodbye to manual paperwork and long waiting times; our software
        accelerates the payer management process, saving you valuable time and resources.</p> </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Remittance Management
       </h2>
       <p>
       Managing remittances can be a daunting task, but with our software, it becomes a breeze. Our platform offers comprehensive remittance management features that enable you to reconcile payments, identify discrepancies, and track revenue effortlessly. By streamlining the remittance process, we ensure that you receive accurate payments in a timely manner, 
       improving your cash flow and financial stability.</p>
       </div>
       <div className='border px-6 py-6 mb-4'>
       <h2 className='font-semibold pb-3'>
       Hassle-Free Operations Management
       </h2>
       <p>
       We understand the importance of smooth operations in the healthcare industry. That's why our software is designed to simplify every aspect of claim management, from initial submission to final reimbursement. With advanced automation, customizable workflows, and real-time analytics, you can optimize your operations, minimize errors, and enhance overall efficiency. Our user-friendly interface makes it easy for your team to navigate the software, reducing training time and ensuring seamless adoption.   </p>
       </div>
       
       
       
       </div>
       <div className=' offering-bg py-6'>
       
       <div className='grid grid-cols-1 lg:grid-cols-2 bg-white mx-6 my-6'>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold uppercase text-2xl'>
       Benefits
       </h2>
       
      <li>Seamless integration with leading health insurance companies</li>	
 <li>	Automated eligibility verification and claim submission</li>	
 <li>	Real-time tracking of claims status and reimbursements</li>	
 <li>	Comprehensive remittance management tools</li>	
 <li>	Customizable workflows to suit your specific needs</li>	
 <li>	Advanced analytics for performance monitoring and optimization</li>	
 <li>	Secure data storage and compliance with industry regulations</li>	

       </div>
       <div className='border-r-2 py-4 px-4'>
       <h2 className='text-red-500 font-bold uppercase text-2xl'>
       Why Choose Us
       </h2>
       <p>
       <li>	Experience: With years of expertise in healthcare technology, we understand the unique needs of our clients and have tailored our software to meet those needs effectively.</li>
<li>	Reliability: Our software is trusted by healthcare providers across the industry, delivering consistent results and unparalleled performance.</li>
<li>	Support: We provide dedicated customer support to ensure that your experience with our software is always smooth and hassle-free. Our team is available to assist you with any questions or issues you may encounter.</li>
<li>	Innovation: We are committed to continuous improvement and innovation, constantly updating our software to incorporate the latest advancements in technology and industry best practices.</li>

      
 </p>
       </div>
       
       
       
       </div>
       <div className='border-r-2 py-4 px-4'>
       
       <p>
       Experience the difference that our Health Insurance Claim Management Software can make for your organization. Streamline your operations, improve efficiency, and enhance profitability with our intuitive and powerful solution. 
       Get in touch with us today to schedule a demo and see our software in action!</p>
       </div>
       
       </div>
       </div>
  )
}

export default ezrx
